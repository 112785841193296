import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';

import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import {
  AC_ClearEditedCalendarEventState,
  ClearEditedCalendarEvent,
  ClearViewCalendarEvent,
  DeleteCalendarEventAction,
  SetCalendarSideFormModeAction,
  SetEditedCalendarEvent,
  UpdateEventAction,
} from 'actions/calendar.actions';
import { AC_setIntermediateActivitiesAction } from 'actions/filter.actions';
import { AC_GetVideoEpisodesSuccess } from 'actions/player.acitons';
import { ReactComponent as DeleteIconRed } from 'assets/img/DeleteIconRed.svg';
import { ReactComponent as EditIconBlue } from 'assets/img/EditIconBlue.svg';
import { ReactComponent as FieldIconBlue } from 'assets/img/FieldIconBlue.svg';
import { ReactComponent as FieldIconGrey } from 'assets/img/FieldIconGrey.svg';
import { ReactComponent as MarkupIconBlue } from 'assets/img/MarkupIconBlue.svg';
import { ReactComponent as MarkupIconGrey } from 'assets/img/MarkupIconGrey.svg';
import { ReactComponent as MatchRecordIconBlue } from 'assets/img/MatchRecordIconBlue.svg';
import { ReactComponent as MatchRecordIconGrey } from 'assets/img/MatchRecordIconGrey.svg';
import { ReactComponent as StatsIconBlue } from 'assets/img/StatsIconBlue.svg';
import { ReactComponent as StatsIconGrey } from 'assets/img/StatsIconGrey.svg';
import { ReactComponent as UserIconBlue } from 'assets/img/UserOutlineIconBlue.svg';
import { ReactComponent as UserIconGrey } from 'assets/img/UserOutlineIconGrey.svg';
import './index.css';
import { AppStateType } from 'reducers';
import { teamReducer } from 'reducers/team.reducer';
import { useAppDispatch } from 'store';
import { MONTHS_ENDING } from 'types/constants';
import { adjustHeightOverflow } from 'types/functions';
import {
  CalendarDayEventType,
  CalendarEventUpdateTypeAPI,
  CalendarSideModeMode,
  VideoFileType,
} from 'types/types';

import VideoUploadController from '../../EventControlPanel/VideoUploadController';
import CameraControlButton from '../../Library/buttons/CameraControlButton';
import ConfirmActionModal, {
  StyleOptions,
} from '../../modals/ConfirmActionModal';

interface EventDetailsModalInterface {
  reference?: any;
  closeCallback?: any;
}

const EventDetailsModal: FC<EventDetailsModalInterface> = ({
  closeCallback,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const setWindowDimensions = () => {
    // console.log("UPDATED W L", window.innerWidth, window.innerHeight)
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener('resize', setWindowDimensions);
    return () => {
      window.removeEventListener('resize', setWindowDimensions);
    };
  }, []);

  const { viewedEvent, currentReferencePositions, editedEvent } = useSelector(
    (state: AppStateType) => state.calendarReducer,
  );
  const { currentTeam } = useSelector(
    (state: AppStateType) => state.clubsReducer,
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeCamera, setActiveCamera] = useState(viewedEvent?.camera);
  const currentModalReference = useRef<any>(null);
  const [leftPosition, setLeftPosition] = useState<number>();
  const [topPosition, setTopPosition] = useState<number>();
  const [fileNames, setFileNames] = useState<Array<any>>([]);
  // const [fileTooBigError, setFileTooBigError] = useState<boolean>(false);
  // const [showUploadError, setShowUploadError] = useState<boolean>(false);

  const calculateModalPosition = () => {
    const h = currentModalReference?.current?.offsetHeight / 2;
    const x = currentReferencePositions?.top - h;
    const adjustedTop =
      x -
      adjustHeightOverflow(
        currentModalReference?.current?.getBoundingClientRect().height,
        x,
      );
    if (viewedEvent && currentModalReference?.current && h) {
      setTopPosition(adjustedTop);
      setLeftPosition(
        currentReferencePositions.left + currentReferencePositions.width,
      );
    }
  };
  const { setStatsCustomGameList } = teamReducer.actions;

  useLayoutEffect(() => {
    calculateModalPosition();
    viewedEvent?.activity_videos !== undefined &&
      setFileNames(viewedEvent?.activity_videos);
  }, [viewedEvent]);
  useLayoutEffect(() => {
    calculateModalPosition();
  }, [fileNames]);

  const updateEventWithNewVideo = () => {
    if (viewedEvent?.id) {
      const event_videos = fileNames.filter((file: VideoFileType) => {
        if (file.id) {
          return { id: file.id, name: file.name };
        }
      });
      const updatedEvent: CalendarEventUpdateTypeAPI = {
        id: viewedEvent.id,
        event_videos: event_videos,
        teams: viewedEvent.teams,
      };
      updatedEvent.id &&
        dispatch(UpdateEventAction(updatedEvent.id, updatedEvent));
    }
  };
  const updateEventWithoutVideo = (videoId: string) => {
    if (viewedEvent?.id) {
      const event_videos = fileNames.filter((file: VideoFileType) => {
        if (file.id !== videoId) {
          return { id: file.id, name: file.name };
        }
      });
      const updatedEvent: CalendarEventUpdateTypeAPI = {
        id: viewedEvent.id,
        event_videos: event_videos,
        teams: viewedEvent.teams,
      };
      updatedEvent.id &&
        dispatch(UpdateEventAction(updatedEvent.id, updatedEvent));
    }
  };
  const makeEventDate = () => {
    if (viewedEvent) {
      // @ts-ignore
      const offset = dayjs().tz(viewedEvent.date.time_zone).utcOffset() / 60;
      let dateString = `${viewedEvent?.date.day} ${MONTHS_ENDING[viewedEvent?.date.month]} ${viewedEvent?.date.year} ${t('from')} ${viewedEvent?.date.startHour}:${viewedEvent?.date.startMinute.toString().padStart(2, '0')} (UTC${offset >= 0 ? '+' : ''}${offset})`;
      // console.log('finish hour', viewedEvent?.date)
      if (viewedEvent?.date.finishHour) {
        dateString = `${dateString} ${t('to')} ${viewedEvent?.date.finishHour}:${viewedEvent?.date.finishMinute?.toString().padStart(2, '0')}`;
      }
      return dateString;
    }
  };
  // const videoUploadRef = useRef<any>(null);
  //
  // const removeFileFromList = (index: number) => {
  //     if (fileNames){
  //         const newList = [...fileNames.slice(0, index), ...fileNames.slice(index+1)];
  //         setFileNames(newList);
  //     }
  // }
  const dispatch = useAppDispatch();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const deleteEvent = () => {
    viewedEvent && dispatch(DeleteCalendarEventAction(viewedEvent));
    if (closeCallback) {
      closeCallback();
    }
    dispatch(ClearViewCalendarEvent());
    if (editedEvent) {
      dispatch(SetCalendarSideFormModeAction(CalendarSideModeMode.create));
      dispatch(ClearEditedCalendarEvent());
    }
    dispatch(AC_ClearEditedCalendarEventState());
    setModalIsOpen(false);
  };
  const editCurrentlyOpenedEvent = () => {
    if (viewedEvent) {
      setModalIsOpen(false);
      dispatch(SetEditedCalendarEvent(viewedEvent));
      dispatch(SetCalendarSideFormModeAction(CalendarSideModeMode.edit));
      dispatch(ClearViewCalendarEvent());
    }
  };
  const canStartCamera = () => {
    if (viewedEvent?.date) {
      const today = dayjs(new Date());
      const eventDate = dayjs(
        new Date(
          viewedEvent?.date.year,
          viewedEvent?.date.month,
          viewedEvent?.date.day,
        ),
      );
      return today.isSame(eventDate, 'date');
    }
    return false;
  };
  const [t] = useTranslation();

  // const makeFileName = (file: any) => {
  //   if (file.meta) {
  //     return file.meta.initial_file_name;
  //   }
  //   return file.name.slice(0, 25);
  // };
  const navigate = useNavigate();
  const openActivityVideos = (event: CalendarDayEventType | null) => {
    if (
      event &&
      event.activity_videos &&
      event.activity_videos.length > 0 &&
      closeCallback
    ) {
      closeCallback();
      setModalIsOpen(false);
      dispatch(ClearViewCalendarEvent());
      const ids: Array<string> = [];
      for (const v of event.activity_videos) {
        ids.push(v.id);
      }
      event.id && dispatch(AC_setIntermediateActivitiesAction([event.id]));
      event.id && dispatch(setStatsCustomGameList([event?.id]));
      dispatch(AC_GetVideoEpisodesSuccess([]));
      if (ids.length > 0) {
        navigate(
          `/player?${ids.map(() => `eventId=${event.id}`)}&teamId=${currentTeam?.id}&tid=${currentTeam?.id}`,
        );
      }
    }
  };
  // const validateFile = (file: any) => {
  //     if (file?.size > MAX_UPLOAD_VIDEO_SIZE) {
  //         setFileTooBigError(true);
  //         setTimeout(() => {
  //             setFileTooBigError(false);
  //         }, 4000);
  //         return false;
  //     }
  //     return true;
  // }
  // const tempFileList: Array<any> = [];
  // const updateFilesList = (e: any) => {
  //     // console.log('UPDATE FILES LIST', e.target.files.length)
  //     let newFile  = e.target.files[0];
  //     const isValid = validateFile(newFile);
  //     newFile.isSent = false;
  //     newFile.status = isValid ? progressStatus.normal : progressStatus.exception;
  //     tempFileList.push(newFile);
  //     if (tempFileList.length > 0) {
  //         // console.log("ADDING TO FILENAMES")
  //         setFileNames((prevList: any) => [...prevList, newFile]);
  //     }
  //     dispatch(AC_FileUploadInProgress(true));
  // }

  return (
    <>
      {' '}
      {viewedEvent && (
        <div
          ref={currentModalReference}
          style={{
            left: `${leftPosition}px`,
            top: `${topPosition}px`,
          }}
          className={'eventEditModal'}
        >
          <div className={'j-sb editedEventModal_EventTime'}>
            <div>
              <span>{makeEventDate()}</span>
            </div>
            <EditIconBlue
              className={'enabledClickable'}
              onClick={editCurrentlyOpenedEvent}
            />
          </div>
          <div className={'flex-column j-sb eventEditModal_Header'}>
            <div>{viewedEvent?.name}</div>
            <div style={{ wordBreak: 'break-all' }}>
              {viewedEvent?.description}
            </div>
          </div>
          <div className="eventEditModal_Divider" />
          {viewedEvent.camera?.name && (
            <>
              <div className={'eventEditModal_CameraBlock'}>
                <div>{viewedEvent?.camera?.name}</div>
                {activeCamera && (
                  <CameraControlButton
                    activeCamera={activeCamera}
                    isEnabled={true}
                  />
                )}
              </div>
              {activeCamera && canStartCamera() && (
                <CameraControlButton
                  activeCamera={activeCamera}
                  isEnabled={true}
                />
              )}
            </>
          )}
          {viewedEvent.activity_videos && (
            <>
              <div
                className={
                  'eventEditModal_VideoFilesBlock videoUploaderContainer'
                }
              >
                {/*{*/}
                {/*    fileNames.length > 0 && <div className={"flex-column"} style={{gap: "8px"}}>*/}
                {/*        {*/}
                {/*            fileNames?.map((file: any, index:number) => {*/}
                {/*                return <span className={"flex-row fileListItem"}>*/}
                {/*                {*/}
                {/*                    file.camera ? <CameraOutlineIcon style={{width: "24px", height: "24px"}} /> :*/}
                {/*                        <UploadGreyIcon style={{width: "24px", height: "24px"}} />*/}
                {/*                }*/}
                {/*                    <div style={{flexGrow: 1, margin: "auto"}}>{makeFileName(file)}</div>*/}
                {/*                <ClearCrossIcon*/}
                {/*                    style={{cursor: "pointer"}}*/}
                {/*                    onClick={() => removeFileFromList(index)}*/}
                {/*                />*/}
                {/*            </span>*/}
                {/*            })*/}
                {/*        }*/}
                {/*    </div>*/}
                {/*}*/}
                {/*<div className={"timeZoneSelector enabledClickable"}*/}
                {/*     onClick={(e) => {*/}
                {/*         videoUploadRef && videoUploadRef?.current?.click()*/}
                {/*     }}*/}
                {/*>*/}
                {/*    <input type="file"*/}
                {/*           ref={videoUploadRef}*/}
                {/*           style={{display: "none"}}*/}
                {/*           multiple={true}*/}
                {/*           onChange={(e:any) => {*/}
                {/*               updateFilesList(e)*/}
                {/*               // e.target.files.length > 0 && setFileNames(Array.from(e.target.files));*/}
                {/*           }}*/}
                {/*    /><UploadBlueIcon />&nbsp;&nbsp;{t("Upload video or markup")}*/}
                {/*</div>*/}
                <VideoUploadController
                  fileNames={fileNames}
                  setFileNames={setFileNames}
                  deleteCallback={updateEventWithoutVideo}
                  successCallback={updateEventWithNewVideo}
                  tooltipId={'modal'}
                  activityId={viewedEvent.id}
                />
              </div>
              <div className="eventEditModal_Divider" />
            </>
          )}
          <div className={'flex-column eventEditModal_StatsBlock'}>
            <div
              className={`${viewedEvent.hasStats ? 'enabledClickable enabled' : 'disabledClickable disabled'}`}
            >
              {viewedEvent.hasStats ? <StatsIconBlue /> : <StatsIconGrey />}
              <div>{t('Statistics')}</div>
            </div>
            <div
              className={`${viewedEvent.activity_videos && viewedEvent.activity_videos?.length > 0 ? 'enabledClickable enabled' : 'disabledClickable disabled'}`}
              onClick={() => {
                openActivityVideos(viewedEvent);
              }}
            >
              {viewedEvent.activity_videos &&
              viewedEvent.activity_videos?.length > 0 ? (
                <MatchRecordIconBlue />
              ) : (
                <MatchRecordIconGrey />
              )}
              <div>{t('Match record')}</div>
            </div>
            <div
              className={`${viewedEvent.hasProtocol ? 'enabledClickable enabled' : 'disabledClickable disabled'}`}
            >
              {viewedEvent.hasProtocol ? <FieldIconBlue /> : <FieldIconGrey />}
              <div>{t('Protocol')}</div>
            </div>
            <div
              className={`${viewedEvent.hasMarkup ? 'enabledClickable enabled' : 'disabledClickable disabled'}`}
            >
              {viewedEvent.hasMarkup ? <MarkupIconBlue /> : <MarkupIconGrey />}
              <div>{t('Markup')}</div>
            </div>
            <div
              className={`${viewedEvent.hasUknownPlayers ? 'enabledClickable enabled' : 'disabledClickable disabled'}`}
            >
              {viewedEvent.hasUknownPlayers ? (
                <UserIconGrey />
              ) : (
                <UserIconBlue />
              )}
              <div>{t('Players matching')}</div>
            </div>
          </div>
          <div className="eventEditModal_Divider" />
          <div className={'eventEditModalFooter enabledClickable'}>
            <div onClick={() => setModalIsOpen(true)}>
              <DeleteIconRed />
              <span>{t('Delete')}</span>
            </div>
          </div>
        </div>
      )}
      <ConfirmActionModal
        title={t('Delete event?')}
        contentText={t('You cannot restore event.')}
        style={StyleOptions.warning}
        actionText={t('Delete')}
        actionCallback={deleteEvent}
        isModalOpen={modalIsOpen}
        setIsModalOpen={setModalIsOpen}
      />
    </>
  );
};
export default EventDetailsModal;
