import { createSlice } from '@reduxjs/toolkit';

import { personaPercentilesType } from 'types/types';

type InitialStateType = {
  teamPlayerStatsState: 'init' | 'loading' | 'loaded' | 'error';
  teamPlayerStatsMatches: any; // консолидированые данные по сезонам/турниру/играм игрока для статистики
  timeOnField: number;
  timeOnFieldByPosition: any;

  tableMatches: any[]; // список игр, отображаемых в окне показа для фильтрации статистики
  selectedRowKeys: string[] | undefined; // список id игр, выбранных в таблице статистики
  appliedMatches: string[]; // список id игр, по которым была применена фильтация. записывается после закрытия модалки по кнопке apply
  checkedSeasons: string[]; // список id выбранных сезонов в модалке статистики
  appliedSeasons: string[]; // список id сезонов, по которым была применена фильтация. записывается после закрытия модалки по кнопке apply
  appliedTournaments: string[]; // список id турниров, по которым была применена фильтация. записывается после закрытия модалки по кнопке apply
  selectedTournaments: string[]; // список id выбранных турниров в модалке статистики

  expandedSeasons: string[]; // список раскрытых сезонов в дереве модалки

  selectedLastMatch: number | string | null;
  appliedLastMatch: number | string | null;
  selectedMatches: any[];
  selectedSeasons: string[];
  personaPercentiles: personaPercentilesType[];
  teamPlayerStats: any[];
  statsState: 'init' | 'loading' | 'loaded' | 'error';
  initializeGamesCommand: boolean;
};
const initialState: InitialStateType = {
  teamPlayerStatsState: 'init',
  teamPlayerStatsMatches: [],
  timeOnField: 0,
  timeOnFieldByPosition: {},

  tableMatches: [],
  checkedSeasons: [],
  expandedSeasons: [],

  selectedLastMatch: null,
  appliedLastMatch: null,
  selectedMatches: [],
  personaPercentiles: [],
  statsState: 'init',
  selectedSeasons: [],
  selectedTournaments: [],
  appliedMatches: [],
  appliedSeasons: [],
  appliedTournaments: [],
  teamPlayerStats: [],
  selectedRowKeys: undefined,
  initializeGamesCommand: false,
};

export const teamPlayerStatsReducer = createSlice({
  name: 'personaReducer',
  initialState: initialState,
  reducers: {
    setTeamPlayerStats(state, action) {
      state.teamPlayerStats = action.payload;
    },
    setTeamPlayerStatsState(state, action) {
      state.teamPlayerStatsState = action.payload;
    },
    setTimeOnField(state, action) {
      state.timeOnField = action.payload;
    },
    setTimeOnFieldByPosition(state, action) {
      state.timeOnFieldByPosition = action.payload;
    },
    setStatsState(state, action) {
      state.statsState = action.payload;
    },
    setSelectedLastMatch(state, action) {
      state.selectedLastMatch = action.payload;
    },
    setTeamPlayerMatches(state, action) {
      state.teamPlayerStatsMatches = action.payload;
    },
    setTableMatches(state, action) {
      state.tableMatches = action.payload;
    },
    setSelectedMatches(state, action) {
      state.selectedMatches = action.payload;
    },
    toggleSelectedMatch(state, action) {
      if (state.selectedMatches.includes(action.payload)) {
        state.selectedMatches = state.selectedMatches.filter(
          (item) => item !== action.payload,
        );
      } else {
        state.selectedMatches.push(action.payload);
      }
    },
    setPesonaPercentiles(state, action) {
      state.personaPercentiles = action.payload;
    },
    setSelectedSeasons(state, action) {
      state.selectedSeasons = action.payload;
    },
    setSelectedTournaments(state, action) {
      state.selectedTournaments = action.payload;
    },
    setAppliedMatches(state, action) {
      state.appliedMatches = action.payload;
    },
    setAppliedSeasons(state, action) {
      state.appliedSeasons = action.payload;
    },
    setAppliedTournaments(state, action) {
      state.appliedTournaments = action.payload;
    },
    setAppliedLastMatches(state, action) {
      state.appliedLastMatch = action.payload;
    },
    setSelectedRowKeys(state, action) {
      state.selectedRowKeys = action.payload;
    },
    setInitializeGamesCommand(state, action) {
      state.initializeGamesCommand = action.payload;
    },
    setCheckedSeasons(state, action) {
      state.checkedSeasons = action.payload;
    },
    setExpandedSeasons(state, action) {
      state.expandedSeasons = action.payload;
    },
  },
});
