import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { GetCurrentUserAction } from 'actions/auth.actions';
import { AC_OpenFilterAction } from 'actions/filter.actions';
import { setStyleModeAction } from 'actions/interface.actions';
import {
  AC_SetActiveVideoAction,
  AC_SetPlayerLoadingAction,
} from 'actions/player.acitons';
import ContentContainer from 'components/ContentContainer';
import VideoDownloadsController from 'components/VideoDownloadsController';
import { playerReducerV2 } from 'reducers/player.reducer';
import { playlistReducerV2 } from 'reducers/playlist.reducer';
import { useAppDispatch } from 'store';
import { STYLE_TYPES } from 'types/constants';

const VideoDownloadsPage = () => {
  const dispatch = useAppDispatch();
  const {
    AC_setPlaylistsAndFolders,
    AC_setOpenedPlaylist,
    AC_setOpenedPlaylistId,
  } = playlistReducerV2.actions;
  const { resetEditMode, setCreateEpisodeMode } = playerReducerV2.actions;
  const [t] = useTranslation();
  useEffect(() => {
    dispatch(GetCurrentUserAction());
    dispatch(AC_OpenFilterAction(''));
    dispatch(setStyleModeAction(STYLE_TYPES.dark));
    dispatch(AC_SetActiveVideoAction(null));
    dispatch(AC_SetPlayerLoadingAction(false));
    dispatch(AC_setPlaylistsAndFolders([]));
    dispatch(AC_setOpenedPlaylist(null));
    dispatch(AC_setOpenedPlaylistId(null));
    dispatch(setCreateEpisodeMode(false));
    dispatch(resetEditMode());
    window.document.body.setAttribute('data-theme', 'dark');
    const metaElement = document.getElementById('meta-top-name');
    const titleElement = document.getElementById('title-top-name');
    if (metaElement && titleElement) {
      metaElement.setAttribute('content', t('Downloads'));
      titleElement.innerHTML = t('Downloads');
    }
  }, []);

  return (
    <ContentContainer
      pageTitle={''}
      page={'downloads'}
      theme="dark"
      boxSizing={'border-box'}
      showNavigationPanel={true}
      wideScreenMode={false}
    >
      <VideoDownloadsController />
    </ContentContainer>
  );
};
export default VideoDownloadsPage;
