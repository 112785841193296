import React, { useEffect, useRef, useState } from 'react';

import { ConfigProvider, Flex, Segmented, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import { EventsAPI } from 'api/events';
import { AppStateType } from 'reducers';

import EventsCloud from './EventsCloud';
import PersonalInfo from './PersonalInfo';
import { ReactComponent as FavoritesIcon } from '../../../assets/img/icons/faStarWithPlusDark.svg';

import './index.css';

const PlayerInfoBlock = () => {
  const { openedTeamPlayer, teamPlayerLoading } = useSelector(
    (state: AppStateType) => state.teamPlayerReducer,
  );
  const { playerId } = useParams();
  const [t] = useTranslation();
  const [lastXEvents, setLastXEvents] = useState<any>([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function getFlagEmoji(countryCode: string): string {
    // return String.fromCodePoint(...codePoints);
    return countryCode
      .toUpperCase()
      .split('')
      .map((char: string) => String.fromCodePoint(char.charCodeAt(0) + 0x1f1a5))
      .join('');
  }
  const handleAddToFavorite = () => {
    console.log('handleAddToFavorite');
  };
  useEffect(() => {
    if (playerId) {
      setLastXEvents([]);
      EventsAPI.getPlayerEventsCloud(playerId, 5).then((response) => {
        setTimeout(() => setLastXEvents(response.data), 1000);
      });
    }
  }, [playerId]);

  const navigate = useNavigate();
  const imgRef = useRef<any>(null);
  return (
    <Flex vertical style={{ width: '100%', height: 196 }}>
      <Flex>
        <Flex justify={'space-between'} style={{ width: '100%', marginTop: 8 }}>
          <Flex gap={4} flex={1}>
            <Flex gap={21}>
              <Flex
                align="center"
                justify="center"
                style={{
                  width: 88,
                  height: 88,
                  marginLeft: 8,
                }}
              >
                <img
                  rel="preload"
                  style={{
                    height: '100%',
                  }}
                  src={
                    teamPlayerLoading || !openedTeamPlayer?.photo
                      ? 'https://dev.sota.id/files/player/player_icon.webp'
                      : openedTeamPlayer?.photo
                  }
                />
              </Flex>
              <Flex vertical style={{ marginTop: 4, marginBottom: 12 }}>
                <Flex gap={8} align="center">
                  <div style={{ fontSize: 20 }} className="flag-icon">
                    {openedTeamPlayer?.country ? (
                      openedTeamPlayer.country.flag_image ? (
                        <img
                          width={16}
                          height={12}
                          src={openedTeamPlayer.country.flag_image}
                          alt=""
                        />
                      ) : (
                        getFlagEmoji(openedTeamPlayer?.country?.code)
                      )
                    ) : (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: '&nbsp;',
                        }}
                      />
                    )}
                  </div>
                  <div className="team-player-country-code">
                    {openedTeamPlayer?.country?.code}
                  </div>
                </Flex>
                <div className="team-player-name">
                  {openedTeamPlayer?.first_name} {openedTeamPlayer?.last_name}
                </div>
                <div
                  className="team-player-team-name enabledClickable"
                  onClick={() => navigate(`/teams/${openedTeamPlayer.team.id}`)}
                >
                  {openedTeamPlayer?.team.name}
                </div>
              </Flex>
            </Flex>
          </Flex>
          <Flex gap={4} align="center" justify="center" flex={1}>
            <ConfigProvider
              theme={{
                components: {
                  Segmented: {
                    trackBg: 'var(--segmentedGroupBg)',
                    itemColor: 'red',
                  },
                },
              }}
            >
              <Segmented
                style={{ width: '422px' }}
                size="large"
                color="black"
                block
                options={[t('Overview'), t('Episodes search')]}
                onChange={(value) => {
                  console.log(value); // string
                }}
              />
            </ConfigProvider>
          </Flex>
          <Flex gap={4} flex={1} align="center" justify={'flex-end'}>
            <Tooltip title={t('Add to favorites')}>
              <FavoritesIcon
                className="enabledClickable"
                onClick={() => handleAddToFavorite()}
              />
            </Tooltip>
          </Flex>
        </Flex>
      </Flex>
      <Flex gap={16}>
        <PersonalInfo />
        <div
          className={`team-player-field-container ${openedTeamPlayer?.team?.sport === 2 ? 'footzal' : ''}`}
          ref={imgRef}
        >
          <img
            rel="preload"
            fetchPriority="high"
            src={`${!openedTeamPlayer ? '' : openedTeamPlayer?.team?.sport === 2 ? 'https://dev.sota.id/files/fields/footzal_field.webp' : 'https://dev.sota.id/files/fields/team_player_field_footbal.webp'}`}
            alt=""
          />
          <EventsCloud lastXEvents={lastXEvents} imgRef={imgRef} />
        </div>
      </Flex>
    </Flex>
  );
};
export default PlayerInfoBlock;
