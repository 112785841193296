/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';

import { API_ENDPOINT } from '../types/constants';

axios.defaults.responseType = 'json';

const UUID_HEADER = 'X-Request-UUID';
const SAME_ORIGIN = 'same-origin';
const get = (
  path,
  params,
  additionalHeaders = {},
  response_type = 'json',
  cancelToken = null,
) => {
  const languageCode = localStorage.getItem('i18nextLng') || 'en';
  let headers = {
    'Content-Type': response_type,
    // "X-Request-UUID": localStorage.getItem('uuid'),
    'Accept-Language': languageCode,
    ...additionalHeaders,
  };
  const uuid = localStorage.getItem('uuid');
  if (uuid !== null) {
    headers[UUID_HEADER] = uuid;
  }
  const token = localStorage.getItem('authtoken');
  if (token !== null) {
    headers.Authorization = `Bearer ${token}`;
  }
  return axios({
    headers: headers,
    method: 'get',
    url: path,
    params,
    responseType: response_type,
    cancelToken: cancelToken,
  });
};
const put = (
  path,
  body,
  customHeaders = {},
  params = {},
  response_type = 'json',
  content_type = 'application/json',
  cancelToken = null,
  asBinary = false,
  ...otherParams
) => {
  const languageCode = localStorage.getItem('i18nextLng') || 'en';
  let headers = {
    'Content-Type': content_type,
    UUID_HEADER: localStorage.getItem('uuid'),
    'Accept-Language': languageCode,
    ...customHeaders,
  };
  const uuid = localStorage.getItem('uuid');
  if (uuid !== null) {
    headers[UUID_HEADER] = uuid;
  }
  const token = localStorage.getItem('authtoken');
  if (token !== null) {
    headers.Authorization = `Bearer ${token}`;
  }
  return axios({
    headers: headers,
    credentials: SAME_ORIGIN,
    method: 'put',
    url: path,
    data: asBinary ? body : JSON.stringify(body) ? body : undefined,
    params: params,
    responseType: response_type,
    cancelToken: cancelToken,
    ...otherParams[0],
  });
};
const putv2 = (
  path,
  body,
  customHeaders = {},
  params = {},
  response_type = 'json',
  content_type = false,
  cancelToken = null,
  asBinary = false,
  ...otherParams
) => {
  const languageCode = localStorage.getItem('i18nextLng') || 'en';
  let headers = {
    'Content-Type': false,
    // ...customHeaders,
  };
  // const uuid = localStorage.getItem('uuid');
  // if (uuid !== null) {
  //   headers[UUID_HEADER] = uuid;
  // }
  // const token = localStorage.getItem('authtoken');
  // if (token !== null) {
  //   headers.Authorization = `Bearer ${token}`;
  // }
  return axios({
    headers: headers,
    credentials: SAME_ORIGIN,
    method: 'put',
    url: path,
    data: asBinary ? body : JSON.stringify(body) ? body : undefined,
    params: params,
    responseType: response_type,
    cancelToken: cancelToken,
    ...otherParams[0],
  });
};
const post = (
  path,
  body,
  customHeaders = {},
  params = {},
  response_type = 'json',
  content_type = 'application/json',
  cancelToken = null,
  ...otherParams
) => {
  const languageCode = localStorage.getItem('i18nextLng') || 'en';
  let headers = {
    'Content-Type': content_type,
    UUID_HEADER: localStorage.getItem('uuid'),
    'Accept-Language': languageCode,
    ...customHeaders,
  };
  const uuid = localStorage.getItem('uuid');
  if (uuid !== null) {
    headers[UUID_HEADER] = uuid;
  }
  const token = localStorage.getItem('authtoken');
  if (token !== null) {
    headers.Authorization = `Bearer ${token}`;
  }
  return axios({
    headers: headers,
    credentials: SAME_ORIGIN,
    method: 'post',
    url: path,
    data: JSON.stringify(body) ? body : undefined,
    params: params,
    responseType: response_type,
    cancelToken: cancelToken,
    ...otherParams[0],
  });
};
const patch = (path, body, customHeaders = {}, response_type = 'json') => {
  const languageCode = localStorage.getItem('i18nextLng') || 'en';
  let headers = {
    'Content-Type': 'application/json',
    UUID_HEADER: localStorage.getItem('uuid'),
    'Accept-Language': languageCode,
    ...customHeaders,
  };
  const uuid = localStorage.getItem('uuid');
  if (uuid !== null) {
    headers[UUID_HEADER] = uuid;
  }
  const token = localStorage.getItem('authtoken');
  if (token !== null) {
    headers.Authorization = `Bearer ${token}`;
  }
  return axios({
    headers: headers,
    credentials: SAME_ORIGIN,
    method: 'patch',
    url: path,
    data: JSON.stringify(body) ? body : undefined,
    responseType: response_type,
  });
};
const remove = (path, body, customHeaders, response_type = 'json') => {
  const languageCode = localStorage.getItem('i18nextLng') | 'en';
  let headers = {
    'Content-Type': 'application/json',
    UUID_HEADER: localStorage.getItem('uuid'),
    'Accept-Language': languageCode,
    ...customHeaders,
  };
  const uuid = localStorage.getItem('uuid');
  if (uuid !== null) {
    headers[UUID_HEADER] = uuid;
  }
  const token = localStorage.getItem('authtoken');
  if (token !== null) {
    headers.Authorization = `Bearer ${token}`;
  }
  return axios({
    headers: headers,
    credentials: SAME_ORIGIN,
    method: 'delete',
    url: path,
    data: JSON.stringify(body) ? body : undefined,
    responseType: response_type,
  });
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const setupResponseInterceptor = (_store, _history) => {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response) {
        if (
          [401, 403].includes(error?.response.status) ||
          [401, 403].includes(error?.response.statusCode)
        ) {
          if (
            error?.response.config.url ===
            `${API_ENDPOINT}api/auth/token/refresh/`
          ) {
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('authtoken');
            localStorage.removeItem('redirectTo');
            window.location.href = '/login';
          }
          if (error?.response.data.code === 'token_not_valid') {
            post(`${API_ENDPOINT}api/auth/token/refresh/`, {
              refresh: localStorage.getItem('refreshToken'),
            })
              .then((resp) => {
                localStorage.setItem('authtoken', resp.data.access);
              })
              .catch((_err) => {
                window.location.href = '/login';
              });
          }
        }
        if ([404].includes(error?.response.status)) {
          // store.dispatch(goto404());
          // window.location.href = '/404';
        }
      }
      return Promise.reject(error);
    },
  );
};
const myAxios = {
  GET: get,
  POST: post,
  PUT: put,
  PUTV2: putv2,
  PATCH: patch,
  DELETE: remove,
  DEFAULTS: axios.defaults,
};
export default myAxios;
