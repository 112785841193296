/* eslint-disable indent */
import { ThunkAction } from 'redux-thunk';

import { EventsAPI } from 'api/events';
import { AppStateType } from 'reducers';
import { teamPlayerStatsReducer } from 'reducers/teamPlayerStats.reducer';
import { ActivityType } from 'types/playlist.types';

const {
  setTeamPlayerStatsState,
  setTeamPlayerMatches,
  setSelectedLastMatch,
  setSelectedMatches,
  setTeamPlayerStats,
  setStatsState,
  setTableMatches,
  setSelectedRowKeys,
  setCheckedSeasons,
  setAppliedSeasons,
  setExpandedSeasons,
  setSelectedTournaments,
  setTimeOnFieldByPosition,
  setTimeOnField,
} = teamPlayerStatsReducer.actions;

export const SetStatsState =
  (
    state: 'init' | 'loading' | 'loaded' | 'error',
  ): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch) => {
    try {
      dispatch(setStatsState(state));
    } catch (e) {
      console.error('first error', e);
    }
  };

export const GetPersonaAllMatches =
  (
    playerId: string,
  ): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch) => {
    try {
      dispatch(setTeamPlayerStatsState('loading'));
      try {
        const data = await EventsAPI.getTeamPlayerGamesWithSeasons(
          playerId,
          true,
        );
        if (data.data) {
          dispatch(setTeamPlayerMatches(data.data));
          if (data.data.length > 0) {
            const firstSeasonMatchIds = data.data[0]['leagues'][0][
              'matches'
            ].map((match: any) => match.id);
            dispatch(
              setTableMatches(
                data.data[0]['leagues'][0]['matches'].map((match: any) => {
                  return { key: match.id, gameDetails: match };
                }),
              ),
            );
            dispatch(setSelectedRowKeys(firstSeasonMatchIds));
            dispatch(
              setCheckedSeasons([
                `1-${data.data[0].name}-${data.data[0].leagues[0]?.tournament_id}`,
              ]),
            );
            dispatch(
              setSelectedTournaments([
                `1-${data.data[0].name}-${data.data[0].leagues[0]?.tournament_id}`,
              ]),
            );
            dispatch(setSelectedMatches(data.data[0]['leagues'][0]['matches']));
            dispatch(setExpandedSeasons([`0-${data.data[0].name}`]));
            dispatch(setAppliedSeasons(data.data[0].leagues[0]?.season_id));
          }
        }
        dispatch(setTeamPlayerStatsState('loaded'));
      } catch (e) {
        dispatch(setTeamPlayerStatsState('error'));
      }
    } catch (e) {
      dispatch(setTeamPlayerStatsState('error'));
    }
  };

export const SetSelectedLastMatch =
  (
    count: number | string | null,
  ): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch) => {
    try {
      dispatch(setSelectedLastMatch(count));
    } catch (e) {
      console.error('first error', e);
    }
  };

export const SelectGame =
  (
    match: ActivityType,
  ): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch, getState) => {
    try {
      const [selectedMatches, checkedSeasons] =
        getState().teamPlayerStatsReducer.selectedMatches;
      if (selectedMatches.includes(match)) {
        dispatch(
          setCheckedSeasons(checkedSeasons.filter((key: string) => key !== '')),
        );
        dispatch(
          setSelectedMatches(
            selectedMatches.filter((item: any) => item !== match),
          ),
        );
      } else {
        dispatch(setSelectedMatches([...selectedMatches, match]));
      }
    } catch (e) {
      console.error('first error', e);
    }
  };

export const ClearSelectedGames =
  (): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch) => {
    try {
      dispatch(setSelectedMatches([]));
    } catch (e) {
      console.error('first error', e);
    }
  };

export const FilterApply =
  (
    checkedSeasons: string[],
    selectedMatchesIds: any[],
  ): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch, getState) => {
    console.log('filter applied');
    dispatch(setStatsState('loading'));
    try {
      let seasonId = 'manual';
      console.log(selectedMatchesIds);
      let tournamentIdForFilter;
      if (checkedSeasons.length === 1) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [index, seasonName, tournamentId] = checkedSeasons[0].split('-');
        const tournament = getState()
          .teamPlayerStatsReducer.teamPlayerStatsMatches.filter(
            (season: any) => season.name === seasonName,
          )[0]
          .leagues.filter(
            (league: any) =>
              league.tournament_id.toString() === tournamentId.toString(),
          )[0];
        if (tournament.matches.length === selectedMatchesIds.length) {
          seasonId = tournament.season_id;
          selectedMatchesIds = [];
          tournamentIdForFilter = tournamentId;
        }
      }
      const playerId = getState().teamPlayerReducer.openedTeamPlayer?.id;
      // alert('2');
      const response = await EventsAPI.getPlayerGamesStatsByPost(
        seasonId,
        playerId,
        '', //selectedPositions || '',
        tournamentIdForFilter,
        selectedMatchesIds,
        false,
      );
      const finalStats: any = [];
      getState().teamPlayerStatsReducer.teamPlayerStats.forEach((stat) => {
        const populatedStat: any = {
          label: stat.label,
          key: stat.key,
          excluded_sports: stat.excluded_sports,
          for_sport: stat.for_sport,
          stat: response?.data?.stats.filter(
            (s: any) => s.key === stat?.key,
          )[0],
        };
        if (stat.children?.length > 0) {
          populatedStat.children = [];
          stat.children.forEach((childStat: any) => {
            const childPopulatedStat = {
              ...childStat,
              excluded_sports: childStat.excluded_sports,
              for_sport: childStat.for_sport,
              stat: response.data.stats.filter(
                (s: any) => s.key === childStat.key,
              )[0],
            };
            populatedStat.children.push(childPopulatedStat);
          });
        }
        finalStats.push(populatedStat);
      });

      dispatch(setTeamPlayerStats(finalStats));
      dispatch(setTimeOnField(response.data.time_on_field));
      dispatch(
        setTimeOnFieldByPosition(
          response.data.stats.filter((s: any) => {
            return s.key === 'played_time';
          })[0]?.by_position,
        ),
      );
      dispatch(setStatsState('loaded'));
    } catch (e) {
      dispatch(setStatsState('error'));
      console.error('first error', e);
    }
  };
