import React from 'react';

import { Checkbox, ConfigProvider, Flex, Skeleton } from 'antd';

import { ReactComponent as ChevronRight } from 'assets/img/icons/faChevronRight.svg';

const VideoListSkeleton = () => {
  return (
    <Flex vertical gap={8}>
      <Flex className="gameVideoContainer gameVideoContainer-v2">
        <div className="baseIconWrapper">
          <ChevronRight className={'baseIcon'} />
        </div>
        <Flex vertical gap={12}>
          <Skeleton.Button
            active
            shape="round"
            style={{
              width: 120,
              height: 14,
              background:
                'linear-gradient(90deg, rgba(255, 255, 255, 0.1) 25%, var(--ant-skeleton-gradient-to-color) 37%, rgba(255, 255, 255, 0.1) 63%)',
              backgroundSize: '400% 100%',
            }}
          />
          <Flex gap={12}>
            <Skeleton.Button
              active
              shape="round"
              style={{
                width: 70,
                height: 14,
                background:
                  'linear-gradient(90deg, rgba(255, 255, 255, 0.1) 25%, var(--ant-skeleton-gradient-to-color) 37%, rgba(255, 255, 255, 0.1) 63%)',
                backgroundSize: '400% 100%',
              }}
            />
            <Skeleton.Button
              active
              shape="round"
              style={{
                width: 30,
                height: 14,
                background:
                  'linear-gradient(90deg, rgba(255, 255, 255, 0.1) 25%, var(--ant-skeleton-gradient-to-color) 37%, rgba(255, 255, 255, 0.1) 63%)',
                backgroundSize: '400% 100%',
              }}
            />
            <Skeleton.Button
              active
              shape="round"
              style={{
                width: 40,
                height: 14,
                background:
                  'linear-gradient(90deg, rgba(255, 255, 255, 0.1) 25%, var(--ant-skeleton-gradient-to-color) 37%, rgba(255, 255, 255, 0.1) 63%)',
                backgroundSize: '400% 100%',
              }}
            />
          </Flex>
        </Flex>
        <div>
          <ConfigProvider
            theme={{
              token: {
                colorBgContainer: '#141414',
                colorBorder: '#424242',
                lineWidth: 1,
              },
            }}
          >
            <Checkbox checked={true} />
          </ConfigProvider>
        </div>
      </Flex>
      <Flex
        className="gameVideoContainer gameVideoContainer-collapsed"
        style={{
          opacity: '0.8',
        }}
      >
        <div className="baseIconWrapper">
          <ChevronRight className={'baseIcon'} />
        </div>
        <Flex vertical gap={12}>
          <Skeleton.Button
            active
            shape="round"
            style={{
              width: 120,
              height: 14,
              background:
                'linear-gradient(90deg, rgba(255, 255, 255, 0.1) 25%, var(--ant-skeleton-gradient-to-color) 37%, rgba(255, 255, 255, 0.1) 63%)',
              backgroundSize: '400% 100%',
            }}
          />
          <Flex gap={12}>
            <Skeleton.Button
              active
              shape="round"
              style={{
                width: 70,
                height: 14,
                background:
                  'linear-gradient(90deg, rgba(255, 255, 255, 0.1) 25%, var(--ant-skeleton-gradient-to-color) 37%, rgba(255, 255, 255, 0.1) 63%)',
                backgroundSize: '400% 100%',
              }}
            />
            <Skeleton.Button
              active
              shape="round"
              style={{
                width: 30,
                height: 14,
                background:
                  'linear-gradient(90deg, rgba(255, 255, 255, 0.1) 25%, var(--ant-skeleton-gradient-to-color) 37%, rgba(255, 255, 255, 0.1) 63%)',
                backgroundSize: '400% 100%',
              }}
            />
            <Skeleton.Button
              active
              shape="round"
              style={{
                width: 40,
                height: 14,
                background:
                  'linear-gradient(90deg, rgba(255, 255, 255, 0.1) 25%, var(--ant-skeleton-gradient-to-color) 37%, rgba(255, 255, 255, 0.1) 63%)',
                backgroundSize: '400% 100%',
              }}
            />
          </Flex>
        </Flex>
        <div>
          <ConfigProvider
            theme={{
              token: {
                colorBgContainer: '#141414',
                colorBorder: '#424242',
                lineWidth: 1,
              },
            }}
          >
            <Checkbox checked={true} />
          </ConfigProvider>
        </div>
      </Flex>
      <Flex
        className="gameVideoContainer gameVideoContainer-collapsed"
        style={{
          opacity: '0.6',
        }}
      >
        <div className="baseIconWrapper">
          <ChevronRight className={'baseIcon'} />
        </div>
        <Flex vertical gap={12}>
          <Skeleton.Button
            active
            shape="round"
            style={{
              width: 120,
              height: 14,
              background:
                'linear-gradient(90deg, rgba(255, 255, 255, 0.1) 25%, var(--ant-skeleton-gradient-to-color) 37%, rgba(255, 255, 255, 0.1) 63%)',
              backgroundSize: '400% 100%',
            }}
          />
          <Flex gap={12}>
            <Skeleton.Button
              active
              shape="round"
              style={{
                width: 70,
                height: 14,
                background:
                  'linear-gradient(90deg, rgba(255, 255, 255, 0.1) 25%, var(--ant-skeleton-gradient-to-color) 37%, rgba(255, 255, 255, 0.1) 63%)',
                backgroundSize: '400% 100%',
              }}
            />
            <Skeleton.Button
              active
              shape="round"
              style={{
                width: 30,
                height: 14,
                background:
                  'linear-gradient(90deg, rgba(255, 255, 255, 0.1) 25%, var(--ant-skeleton-gradient-to-color) 37%, rgba(255, 255, 255, 0.1) 63%)',
                backgroundSize: '400% 100%',
              }}
            />
            <Skeleton.Button
              active
              shape="round"
              style={{
                width: 40,
                height: 14,
                background:
                  'linear-gradient(90deg, rgba(255, 255, 255, 0.1) 25%, var(--ant-skeleton-gradient-to-color) 37%, rgba(255, 255, 255, 0.1) 63%)',
                backgroundSize: '400% 100%',
              }}
            />
          </Flex>
        </Flex>
        <div>
          <ConfigProvider
            theme={{
              token: {
                colorBgContainer: '#141414',
                colorBorder: '#424242',
                lineWidth: 1,
              },
            }}
          >
            <Checkbox checked={true} />
          </ConfigProvider>
        </div>
      </Flex>
      <Flex
        className="gameVideoContainer gameVideoContainer-collapsed"
        style={{
          opacity: '0.4',
        }}
      >
        <div className="baseIconWrapper">
          <ChevronRight className={'baseIcon'} />
        </div>
        <Flex vertical gap={12}>
          <Skeleton.Button
            active
            shape="round"
            style={{
              width: 120,
              height: 14,
              background:
                'linear-gradient(90deg, rgba(255, 255, 255, 0.1) 25%, var(--ant-skeleton-gradient-to-color) 37%, rgba(255, 255, 255, 0.1) 63%)',
              backgroundSize: '400% 100%',
            }}
          />
          <Flex gap={12}>
            <Skeleton.Button
              active
              shape="round"
              style={{
                width: 70,
                height: 14,
                background:
                  'linear-gradient(90deg, rgba(255, 255, 255, 0.1) 25%, var(--ant-skeleton-gradient-to-color) 37%, rgba(255, 255, 255, 0.1) 63%)',
                backgroundSize: '400% 100%',
              }}
            />
            <Skeleton.Button
              active
              shape="round"
              style={{
                width: 30,
                height: 14,
                background:
                  'linear-gradient(90deg, rgba(255, 255, 255, 0.1) 25%, var(--ant-skeleton-gradient-to-color) 37%, rgba(255, 255, 255, 0.1) 63%)',
                backgroundSize: '400% 100%',
              }}
            />
            <Skeleton.Button
              active
              shape="round"
              style={{
                width: 40,
                height: 14,
                background:
                  'linear-gradient(90deg, rgba(255, 255, 255, 0.1) 25%, var(--ant-skeleton-gradient-to-color) 37%, rgba(255, 255, 255, 0.1) 63%)',
                backgroundSize: '400% 100%',
              }}
            />
          </Flex>
        </Flex>
        <div>
          <ConfigProvider
            theme={{
              token: {
                colorBgContainer: '#141414',
                colorBorder: '#424242',
                lineWidth: 1,
              },
            }}
          >
            <Checkbox checked={true} />
          </ConfigProvider>
        </div>
      </Flex>
      <Flex
        className="gameVideoContainer gameVideoContainer-collapsed"
        style={{
          opacity: '0.2',
        }}
      >
        <div className="baseIconWrapper">
          <ChevronRight className={'baseIcon'} />
        </div>
        <Flex vertical gap={12}>
          <Skeleton.Button
            active
            shape="round"
            style={{
              width: 120,
              height: 14,
              background:
                'linear-gradient(90deg, rgba(255, 255, 255, 0.1) 25%, var(--ant-skeleton-gradient-to-color) 37%, rgba(255, 255, 255, 0.1) 63%)',
              backgroundSize: '400% 100%',
            }}
          />
          <Flex gap={12}>
            <Skeleton.Button
              active
              shape="round"
              style={{
                width: 70,
                height: 14,
                background:
                  'linear-gradient(90deg, rgba(255, 255, 255, 0.1) 25%, var(--ant-skeleton-gradient-to-color) 37%, rgba(255, 255, 255, 0.1) 63%)',
                backgroundSize: '400% 100%',
              }}
            />
            <Skeleton.Button
              active
              shape="round"
              style={{
                width: 30,
                height: 14,
                background:
                  'linear-gradient(90deg, rgba(255, 255, 255, 0.1) 25%, var(--ant-skeleton-gradient-to-color) 37%, rgba(255, 255, 255, 0.1) 63%)',
                backgroundSize: '400% 100%',
              }}
            />
            <Skeleton.Button
              active
              shape="round"
              style={{
                width: 40,
                height: 14,
                background:
                  'linear-gradient(90deg, rgba(255, 255, 255, 0.1) 25%, var(--ant-skeleton-gradient-to-color) 37%, rgba(255, 255, 255, 0.1) 63%)',
                backgroundSize: '400% 100%',
              }}
            />
          </Flex>
        </Flex>
        <div>
          <ConfigProvider
            theme={{
              token: {
                colorBgContainer: '#141414',
                colorBorder: '#424242',
                lineWidth: 1,
              },
            }}
          >
            <Checkbox checked={true} />
          </ConfigProvider>
        </div>
      </Flex>
    </Flex>
  );
};
export default VideoListSkeleton;
