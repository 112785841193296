import React, { FC } from 'react';

import { ConfigProvider, Dropdown, MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { playerReducerV2 } from 'reducers/player.reducer';
import { useAppDispatch } from 'store';

import { AppStateType } from '../../../reducers';
interface Interface {
  changeEpsiodeTimelineCallback: any;
  children: any;
  setOpenContext: any;
}
const EditorIconContext: FC<Interface> = (props: any) => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const { activeVideo, isLoading } = useSelector(
    (state: AppStateType) => state.playerReducer,
  );
  const { playedEpisode, createEpisodeMode, videosListEditMode } = useSelector(
    (state: AppStateType) => state.playerReducerV2,
  );
  const { setCreateEpisodeMode } = playerReducerV2.actions;
  const items: MenuProps['items'] = [
    {
      key: 1,
      label: (
        <div
          className="flex-row j-sb"
          onClick={() => {
            if (
              !(
                activeVideo === null ||
                !!videosListEditMode ||
                createEpisodeMode
              )
            ) {
              dispatch(setCreateEpisodeMode(true));
            }
          }}
        >
          <div>{t('Create episode')}</div>
          <div>CMD+N</div>
        </div>
      ),
      disabled:
        activeVideo === null ||
        !!videosListEditMode ||
        createEpisodeMode ||
        isLoading,
    },
    {
      key: 2,
      label: (
        <div
          className="flex-row j-sb"
          onClick={() => {
            if (
              !(
                playedEpisode === null ||
                activeVideo === null ||
                videosListEditMode !== null ||
                createEpisodeMode
              )
            ) {
              props.changeEpsiodeTimelineCallback();
            }
          }}
        >
          <div style={{ textWrap: 'wrap' }}>{t('Change timeline')}</div>
          <div>ENTER</div>
        </div>
      ),
      disabled:
        playedEpisode === null ||
        activeVideo === null ||
        videosListEditMode !== null ||
        createEpisodeMode ||
        playedEpisode.episode_type === 'period' ||
        isLoading,
    },
  ];
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgElevated: 'var(--colorBgSpotlight)',
          colorText: 'var(--colorText)',
        },
      }}
    >
      <Dropdown
        onOpenChange={props.setOpenContext}
        className="enabledClickable"
        menu={{ items }}
        trigger={['click']}
        overlayStyle={{ width: '221px' }}
        placement="topRight"
      >
        {props.children}
      </Dropdown>
    </ConfigProvider>
  );
};
export default EditorIconContext;
