/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';

import { Divider, Flex } from 'antd';
import { useSelector } from 'react-redux';

import { AC_setIntermediateActivitiesAction } from 'actions/filter.actions';
import { EventsAPI } from 'api/events';
import { AppStateType } from 'reducers';
import { useAppDispatch } from 'store';

import { ReactComponent as GoalGreenIcon } from '../../../../assets/img/icons/faGoalGreenSmall.svg';
import { ReactComponent as GoalIcon } from '../../../../assets/img/icons/faGoalSmall.svg';
import { ReactComponent as PlayCircleFilled } from '../../../../assets/img/icons/faPlayCircleFilled.svg';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ReactComponent as RedCardSmall } from '../../../../assets/img/icons/faRedCardSmall.svg';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ReactComponent as YellowCardSmall } from '../../../../assets/img/icons/faYellowCardSmall.svg';

import './index.css';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GameEventsTags = ({
  match,
  hasEvents,
  playerId,
  isExpanded,
}: {
  match: any;
  hasEvents: boolean;
  playerId: string;
  isExpanded: boolean;
}) => {
  const { openedTeamPlayer } = useSelector(
    (state: AppStateType) => state.teamPlayerReducer,
  );
  const populateGoals = () => {
    EventsAPI.getPlayerGameEventDetails(
      match.id,
      ['goal', 'goal_pass', 'yellow_cards', 'red_cards', 'second_yellow_cards'],
      playerId,
      [],
    ).then((response: any) => {
      setGoalDetails(response.data['goal'].map((evt: any) => evt.time));
      setGoalPassDetails(
        response.data['goal_pass'].map((evt: any) => evt.time),
      );
      setYellowCardsDetails(
        response.data['yellow_cards'].map((evt: any) => evt.time),
      );
      setRedCardsDetails(
        response.data['red_cards'].map((evt: any) => evt.time),
      );
      setSecondYellowCardsDetails(
        response.data['second_yellow_cards'].map((evt: any) => evt.time),
      );
    });
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [goalDetails, setGoalDetails] = useState<string[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [goalPassDetails, setGoalPassDetails] = useState<string[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [redCardsDetails, setRedCardsDetails] = useState<string[]>([]);
  const [yellowCardsDetails, setYellowCardsDetails] = useState<string[]>([]);
  const [secondYellowCardsDetails, setSecondYellowCardsDetails] = useState<
    string[]
  >([]);
  useEffect(() => {
    populateGoals();
  }, []);
  const dispatch = useAppDispatch();
  const makeLink = (match: any, team?: any, stats?: any[], part?: any) => {
    let link = `/player?&eventId=${match.id}&mst=or`;
    if (team) {
      if (part === 'highlights') {
        link = `${link}&teamId=${team?.id}`;
      } else {
        link = `${link}&teamId=${team?.id}`;
      }
    }
    if (stats?.length) {
      stats.forEach((stat: any) => {
        link = `${link}&m=${stat.key}`;
      });
    }
    if (part) {
      link = `${link}&p=${part}`;
    }
    link = `${link}&pl=${openedTeamPlayer.id}`;

    dispatch(AC_setIntermediateActivitiesAction(match ? [match.id] : []));
    // dispatch(setStatsCustomGameList([match.id]));
    return link;
  };
  const goToEvent = (keys: string[]) => {
    window.open(
      makeLink(
        match,
        { id: openedTeamPlayer.team.id },
        keys.map((key) => {
          return { key: key };
        }),
        null,
      ),
      '_blank',
    );
  };
  return (
    <div
      className="flex-row j-fe ai-c"
      style={{ width: 111, overflowX: 'visible' }}
    >
      {hasEvents && (
        <>
          <Flex align="flex-end" gap={8} vertical>
            {goalDetails.length > 0 && (
              <Flex
                className="goal-details"
                justify="flex-end"
                style={{ height: 24 }}
              >
                <Flex
                  className={`visible-goal-details ${isExpanded ? 'expanded' : ''}`}
                  justify="flex-start"
                  style={{
                    position: 'relative',
                    overflow: 'hidden',
                  }}
                >
                  {goalDetails.length > 0 && (
                    <Flex align="center">
                      <PlayCircleFilled
                        className="play-event"
                        onClick={(e) => {
                          e.stopPropagation();
                          goToEvent(['goal']);
                        }}
                      />
                    </Flex>
                  )}
                  {goalDetails.map((detail, index) => (
                    <Flex gap={2} align="center" justify="center" key={index}>
                      <div className="goal-minute">{detail}’</div>
                      <Flex
                        align="center"
                        className="goal-icon"
                        style={{
                          position: 'relative',
                          left: index * 14,
                          zIndex: 5 - index,
                          border: '1px solid #fff',
                          borderRadius: '50%',
                          width: 24,
                          backgroundColor: 'white',
                        }}
                      >
                        <GoalIcon />
                      </Flex>
                    </Flex>
                  ))}
                </Flex>
              </Flex>
            )}
            {goalPassDetails.length > 0 && (
              <Flex
                className="goal-details"
                justify="flex-end"
                style={{ height: 24 }}
              >
                <Flex
                  className={`visible-goal-details ${isExpanded ? 'expanded' : ''}`}
                  justify="flex-start"
                  style={{
                    position: 'relative',
                    overflow: 'hidden',
                  }}
                >
                  {goalPassDetails.length > 0 && (
                    <Flex align="center">
                      <PlayCircleFilled
                        className="play-event"
                        onClick={(e) => {
                          e.stopPropagation();
                          goToEvent(['goal_pass']);
                        }}
                      />
                    </Flex>
                  )}
                  {goalPassDetails.map((detail, index) => (
                    <Flex align="center" justify="center" key={index}>
                      <div className="goal-minute">{detail}’</div>
                      <Flex
                        align="center"
                        className="goal-icon"
                        style={{
                          position: 'relative',
                          left: index * 14,
                          zIndex: 5 - index,
                          border: '1px solid #fff',
                          borderRadius: '50%',
                          width: 24,
                          backgroundColor: 'white',
                        }}
                      >
                        <GoalGreenIcon />
                      </Flex>
                    </Flex>
                  ))}
                </Flex>
              </Flex>
            )}
            {(yellowCardsDetails.length > 0 || redCardsDetails.length > 0) && (
              <Flex
                className="goal-details"
                justify="flex-end"
                style={{ height: 24 }}
              >
                <Flex
                  className={`visible-goal-details ${isExpanded ? 'expanded' : ''}`}
                  justify="flex-start"
                  style={{
                    position: 'relative',
                    overflow: 'hidden',
                  }}
                >
                  {(yellowCardsDetails.length > 0 ||
                    redCardsDetails.length > 0) && (
                    <Flex align="center">
                      <PlayCircleFilled
                        className="play-event"
                        onClick={(e) => {
                          e.stopPropagation();
                          goToEvent([
                            'yellowcard',
                            'secondyellowcard',
                            'redcard',
                          ]);
                        }}
                      />
                    </Flex>
                  )}
                  <Flex
                    style={{
                      flexDirection: 'row-reverse',
                    }}
                    align="center"
                    justify="flex-start"
                  >
                    {yellowCardsDetails.map((detail, index) => (
                      <>
                        <Flex
                          align="center"
                          className="goal-icon"
                          style={{
                            position: 'relative',
                            left: index * 14,
                            zIndex: 5 - index,
                            border: '1px solid #FFF',
                            borderRadius: '3px',
                          }}
                        >
                          <YellowCardSmall />
                        </Flex>
                        <div className="goal-minute">{detail}’</div>
                      </>
                    ))}
                    {secondYellowCardsDetails.map((detail, index) => (
                      <>
                        <Flex
                          align="center"
                          className="goal-icon"
                          style={{
                            position: 'relative',
                            left: (index + 1) * 14,
                            zIndex: 5 - index,
                            border: '1px solid #FFF',
                            borderRadius: '3px',
                          }}
                        >
                          <RedCardSmall />
                        </Flex>
                        <div className="goal-minute">{detail}’</div>
                      </>
                    ))}
                    {redCardsDetails.map((detail, index) => (
                      <>
                        <Flex
                          align="center"
                          className="goal-icon"
                          style={{
                            position: 'relative',
                            left: (yellowCardsDetails.length + index) * 14,
                            zIndex: 5 - index,
                            border: '0px solid #FFF',
                            borderRadius: '3px',
                          }}
                        >
                          <RedCardSmall />
                        </Flex>
                        <div className="goal-minute">{detail}’</div>
                      </>
                    ))}
                  </Flex>
                </Flex>
              </Flex>
            )}
          </Flex>
          <Divider type={'vertical'} style={{ height: 80 }} />
        </>
      )}
    </div>
  );
};
export default GameEventsTags;
