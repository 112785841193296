import { getMatchWord } from 'utils/functions';

export const constructMatchesString = ({
  selectedRowKeys,
  t,
  selectedTournaments,
}: {
  selectedRowKeys: any;
  t: any;
  selectedTournaments: any;
}) => {
  if (selectedRowKeys === undefined) {
    return '';
  }
  const matcheString = `${selectedRowKeys.length} ${t(getMatchWord(selectedRowKeys.length.toString()))}`;
  if (selectedRowKeys.length === 0) {
    return matcheString;
  }
  if (selectedTournaments.length === 1) {
    return `(${matcheString})`;
  }
  return matcheString;
};
