import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { ConfigProvider, Slider, SliderSingleProps } from 'antd';
import { useSelector } from 'react-redux';

import { AC_SetVisibleRangeAction } from 'actions/player.acitons';
import { AppStateType } from 'reducers';
import { playerReducerV2 } from 'reducers/player.reducer';
import { useAppDispatch } from 'store';
import {
  EDIT_VIDEO_WINDOW_WIDTH_MS,
  TIMELINE_ANIMATION_STEP_MS,
  WINDOW_MODE_WIDTH_MS,
} from 'types/constants';
import { formatMillisecondsToTimeWithUndefined } from 'types/functions';
import './index.css';
import { KeyFrameType } from 'types/types';

const VideoPlayerTimeline = ({
  refPlayer,
  playerState,
  to,
  setTo,
  from,
  setFrom,
  // setEditedFrom,
  // setEditedTo,
}: {
  refPlayer: any;
  playerState: any;
  to: any;
  setTo: any;
  from: any;
  setFrom: any;
  setEditedFrom: any;
  setEditedTo: any;
}) => {
  const {
    activeVideo,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isLoading,
  } = useSelector((state: AppStateType) => state.playerReducer);
  const { editedEpisodeRange, editedFileEpisodeRange } = useSelector(
    (state: AppStateType) => state.playerReducerV2,
  );
  const { setEditedEpisodeRange, setEditedEpisodeFileRange } =
    playerReducerV2.actions;
  const { playedEpisode, createEpisodeMode } = useSelector(
    (state: AppStateType) => state.playerReducerV2,
  );
  const dispatch = useAppDispatch();
  // borders of edited or created episode
  const [editEpisodeSegments, setEditEpisodeSegments] = useState<number[]>([
    from,
    to,
  ]);
  const { keyframes, reelsModeOn } = useSelector(
    (state: AppStateType) => state.reelsReducer,
  );
  // left border of visible video timeline
  const [initialMin, setInitialMin] = useState(() => {
    if (editedFileEpisodeRange.length) {
      return editedFileEpisodeRange[0];
    }
    return from;
  });
  // right border of visible video timeline
  const [initialMax, setInitialMax] = useState(() => {
    if (editedFileEpisodeRange.length) {
      return editedFileEpisodeRange[1];
    }
    return to;
  });
  useLayoutEffect(() => {
    dispatch(AC_SetVisibleRangeAction([initialMin, initialMax]));
  }, [initialMin, initialMax]);
  useEffect(() => {
    // console.log(`from ${from}`);
    setEditEpisodeSegments(() => [from, to]);
    if (editedFileEpisodeRange.length > 0) {
      if (
        editedFileEpisodeRange[0] - initialMin < WINDOW_MODE_WIDTH_MS &&
        editedFileEpisodeRange[0] - WINDOW_MODE_WIDTH_MS > 0
      ) {
        const delta = editedFileEpisodeRange[0] - initialMin;
        if (delta > TIMELINE_ANIMATION_STEP_MS) {
          setInitialMin(initialMin - delta / 2);
        } else {
          setInitialMin(initialMin - TIMELINE_ANIMATION_STEP_MS);
        }
      } else if (
        editedFileEpisodeRange[0] - initialMin > 5000 &&
        editedFileEpisodeRange[0] <= editedFileEpisodeRange[1]
      ) {
        setInitialMin(initialMin + TIMELINE_ANIMATION_STEP_MS);
      } else {
        setInitialMin(editedFileEpisodeRange[0]);
      }
    } else if (createEpisodeMode) {
      if (
        editedFileEpisodeRange[0] - initialMin < WINDOW_MODE_WIDTH_MS &&
        editedFileEpisodeRange[0] - WINDOW_MODE_WIDTH_MS > 0
      ) {
        setInitialMin(initialMin - TIMELINE_ANIMATION_STEP_MS);
      } else if (
        editedFileEpisodeRange[0] - initialMin > WINDOW_MODE_WIDTH_MS &&
        editedFileEpisodeRange[0] <= editedFileEpisodeRange[1]
      ) {
        setInitialMin(initialMin + TIMELINE_ANIMATION_STEP_MS);
      } else {
        // setInitialMin(0);
      }
    } else {
      setInitialMin(from);
    }
    if (editedFileEpisodeRange.length > 0) {
      if (initialMax - to < WINDOW_MODE_WIDTH_MS && activeVideo?.duration) {
        if (to + WINDOW_MODE_WIDTH_MS < activeVideo?.duration * 1000) {
          setInitialMax((prev: number) => prev + 200);
        } else {
          setInitialMax(
            activeVideo?.duration ? activeVideo?.duration * 1000 : 0,
          );
        }
      } else if (initialMax - to > 5000 && activeVideo?.duration) {
        setInitialMax((prev: number) => prev - 200);
      }
    } else if (createEpisodeMode) {
      if (
        initialMax - editedFileEpisodeRange[1] < WINDOW_MODE_WIDTH_MS &&
        activeVideo?.duration
      ) {
        if (to + 5000 < activeVideo?.duration * 1000) {
          setInitialMax((prev: number) => prev + TIMELINE_ANIMATION_STEP_MS);
        } else {
          setInitialMax(
            activeVideo?.duration ? activeVideo?.duration * 1000 : 0,
          );
        }
      } else {
        setInitialMax((prev: number) => prev - TIMELINE_ANIMATION_STEP_MS);
      }
    } else {
      setInitialMax(to);
    }
  }, [from, to]);

  // adjust visible video timeline on edited vide border change. For smooth animation
  useEffect(() => {
    let leadLagTime = 0;
    if (editedFileEpisodeRange.length > 0 || createEpisodeMode) {
      leadLagTime = WINDOW_MODE_WIDTH_MS;
    }
    if (
      from < editedEpisodeRange[0] &&
      editedEpisodeRange[0] - from > EDIT_VIDEO_WINDOW_WIDTH_MS
    ) {
      setInitialMin(initialMin);
      return;
    }
    if (from - initialMin < 0) {
      const i = setInterval(() => setInitialMin(from), 1);
      return () => clearInterval(i);
    }
    if (
      // 5000 >= 5000 - (5000 - 2000) && 5000 - (5000 - 2000) > 0
      TIMELINE_ANIMATION_STEP_MS >= leadLagTime - (from - initialMin) &&
      leadLagTime - (from - initialMin) > 0
    ) {
      setInitialMin(initialMin);
      return;
    }
    if (from - initialMin < leadLagTime && from - leadLagTime > 0) {
      const i = setInterval(
        () => setInitialMin(initialMin - TIMELINE_ANIMATION_STEP_MS),
        1,
      );
      return () => clearInterval(i);
    } else if (from - initialMin > leadLagTime && from < to) {
      let i: any;
      if (from - initialMin > TIMELINE_ANIMATION_STEP_MS * 100) {
        i = setInterval(
          () => setInitialMin(initialMin + (from - initialMin) / 2),
          1,
        );
      } else if (from - initialMin > TIMELINE_ANIMATION_STEP_MS * 50) {
        i = setInterval(
          () => setInitialMin(initialMin + TIMELINE_ANIMATION_STEP_MS * 50),
          1,
        );
      } else if (from - initialMin > TIMELINE_ANIMATION_STEP_MS * 10) {
        i = setInterval(
          () => setInitialMin(initialMin + TIMELINE_ANIMATION_STEP_MS * 10),
          1,
        );
      } else {
        i = setInterval(
          () => setInitialMin(initialMin + TIMELINE_ANIMATION_STEP_MS),
          1,
        );
      }
      return () => clearInterval(i);
    }
  }, [initialMin]);
  // adjust visible video timeline on edited vide border change. For smooth animation
  useEffect(() => {
    let leadLagTime = 0;
    if (editedFileEpisodeRange.length > 0 || createEpisodeMode) {
      leadLagTime = WINDOW_MODE_WIDTH_MS;
    }
    if (
      to > editedEpisodeRange[1] &&
      to - editedEpisodeRange[1] > EDIT_VIDEO_WINDOW_WIDTH_MS
    ) {
      setInitialMax(initialMax);
      return;
    }
    if (initialMax - to < 0) {
      const i = setInterval(() => setInitialMax(to), 1);
      return () => clearInterval(i);
    }
    if (
      TIMELINE_ANIMATION_STEP_MS > leadLagTime - (initialMax - to) &&
      leadLagTime - (initialMax - to) > 0
    ) {
      setInitialMax(initialMax);
      return;
    }
    if (
      initialMax - to < leadLagTime &&
      activeVideo?.duration &&
      to > activeVideo?.duration
    ) {
      const i = setInterval(
        () => setInitialMax(initialMax + TIMELINE_ANIMATION_STEP_MS),
        1,
      );
      return () => clearInterval(i);
    } else if (initialMax - to > leadLagTime && from < to) {
      let i: any;
      if (initialMax - to > TIMELINE_ANIMATION_STEP_MS * 100) {
        i = setInterval(
          () => setInitialMax(initialMax - (initialMax - to) / 2),
          1,
        );
      } else if (initialMax - to > TIMELINE_ANIMATION_STEP_MS * 10) {
        i = setInterval(
          () => setInitialMax(initialMax - TIMELINE_ANIMATION_STEP_MS * 10),
          1,
        );
      } else {
        i = setInterval(
          () => setInitialMax(initialMax - TIMELINE_ANIMATION_STEP_MS),
          1,
        );
      }

      return () => clearInterval(i);
    }
  }, [initialMax]);
  const [marks, setMarks] = useState<SliderSingleProps['marks']>({});
  const [keyFrameMarks, setKeyFrameMarks] = useState<
    SliderSingleProps['marks']
  >({});
  const prevEditedRangeRef = useRef<number[]>(editedFileEpisodeRange);
  useEffect(() => {
    if (keyframes.length > 0 && reelsModeOn) {
      const b: any = {};
      keyframes.forEach((keyFrame: KeyFrameType) => {
        b[keyFrame.time] = {
          class: 'custom-keyframe-mark',
          style: {
            width: 15,
            border: '1px red',
            color: 'transparent',
          },
          label: keyFrame.time.toString(),
        };
      });
      setKeyFrameMarks(b);
    } else {
      setKeyFrameMarks({});
    }
  }, [keyframes, reelsModeOn]);
  // react to edit mode turned on
  useEffect(() => {
    if (prevEditedRangeRef.current !== editedFileEpisodeRange) {
      if (
        editedFileEpisodeRange &&
        editedFileEpisodeRange.length > 0 &&
        activeVideo &&
        activeVideo?.duration
      ) {
        const b: any = {};
        b[editedFileEpisodeRange[0]] = {
          class: 'custom-mark',
          style: {
            width: 5,
            color: 'transparent',
          },
          label: 0,
        };
        b[editedFileEpisodeRange[1] + 0] = {
          class: 'custom-mark',
          style: {
            width: 5,
            color: 'transparent',
          },
          label: 1,
        };
        if (marks === undefined || Object.keys(marks).length === 0) {
          setMarks(b);
        }
        if (editedFileEpisodeRange[0] >= WINDOW_MODE_WIDTH_MS * 2) {
          setInitialMin(editedFileEpisodeRange[0] - WINDOW_MODE_WIDTH_MS * 2);
        } else {
          setInitialMin(0);
        }
        if (
          editedFileEpisodeRange[1] + WINDOW_MODE_WIDTH_MS * 2 <=
          activeVideo?.duration * 1000
        ) {
          setInitialMax(editedFileEpisodeRange[1] + WINDOW_MODE_WIDTH_MS * 2);
        } else {
          setInitialMax(activeVideo?.duration * 1000);
        }
      } else if (editedEpisodeRange.length === 0) {
        setMarks({});
      }
      prevEditedRangeRef.current = editedFileEpisodeRange;
    }
  }, [editedFileEpisodeRange]);
  useEffect(() => {
    setMarks({});
  }, [activeVideo]);

  // handle edited episode borders change
  const handleOnChange = (value: number[]) => {
    if (value[0] <= value[1] && playedEpisode) {
      const deltaFrom = from - value[0];
      const deltaTo = to - value[1];
      setFrom(value[0]);
      setTo(value[1]);
      dispatch(
        setEditedEpisodeRange([
          editedEpisodeRange[0] - deltaFrom,
          editedEpisodeRange[1] - deltaTo,
        ]),
      );
      dispatch(setEditedEpisodeFileRange(value));
    }
  };
  const formatHandleTooltip = (value: number | undefined) => {
    let finalValue = value;
    if (value !== undefined && value !== null) {
      let offset;
      if (
        playedEpisode?.user_timer !== undefined &&
        playedEpisode?.user_timer !== null
      ) {
        offset =
          playedEpisode?.user_timer?.started_at -
          playedEpisode?.game_timer?.started_at;
      } else {
        offset =
          playedEpisode?.file_timer.started_at -
          playedEpisode?.game_timer.started_at;
      }
      // console.log(`offset ${offset}`);
      finalValue = value - offset;
    }
    // console.log(`value ${value}`);
    // console.log(`finalValue ${finalValue}`);
    return formatMillisecondsToTimeWithUndefined(finalValue, true);
  };
  const sliderRef = useRef<any>();
  return (
    <div
      style={{
        width: '100%',
        marginBottom: 10,
      }}
    >
      <>
        {(createEpisodeMode || editedFileEpisodeRange.length > 0) && (
          <ConfigProvider
            theme={{
              components: {
                Slider: {
                  railBg: 'var(--colorFill)',
                  railHoverBg: 'var(--colorFill)',
                  railSize: 4,
                  trackBg: 'var(--colorPrimaryHover, rgba(60, 137, 232, 1))',
                  handleActiveColor:
                    'var(--colorPrimaryHover, rgba(60, 137, 232, 1))',
                  handleColor:
                    'var(--colorPrimaryHover, rgba(60, 137, 232, 1))',
                  handleSizeHover: 10,
                  handleSize: 10,
                  handleLineWidthHover: 2,
                  handleLineWidth: 2,
                },
              },
            }}
          >
            <Slider
              style={{ margin: 0 }}
              range
              className="custom-slider"
              tooltip={{
                formatter: formatHandleTooltip,
                autoAdjustOverflow: true,
              }}
              min={initialMin}
              marks={marks}
              onChange={(value: any) => {
                if (value[0] <= value[1]) {
                  setEditEpisodeSegments(value);
                }
              }}
              onChangeComplete={(value: any) => {
                handleOnChange(value);
              }}
              styles={{
                track: {
                  background: 'white',
                },
                tracks: {
                  background: 'rgba(255, 255, 255, 0.18)',
                },
              }}
              defaultValue={editEpisodeSegments}
              value={editEpisodeSegments}
              max={initialMax}
              classNames={{ handle: 'handleclassx' }}
              step={1}
            />
          </ConfigProvider>
        )}
        <ConfigProvider
          theme={{
            components: {
              Slider: {
                railBg: 'var(--colorFill)',
                railHoverBg: 'var(--colorFill)',
                railSize: 4,
                trackBg:
                  editedFileEpisodeRange.length > 0 || createEpisodeMode
                    ? 'transparent'
                    : 'var(--colorPrimaryHover)',
                handleActiveColor:
                  'var(--colorPrimaryHover, rgba(60, 137, 232, 1))',
                handleColor: 'var(--colorPrimaryHover, rgba(60, 137, 232, 1))',
                handleSizeHover: 10,
                handleSize: 10,
                handleLineWidthHover: 2,
                handleLineWidth: 2,
                colorPrimaryBorderHover:
                  'var(--colorPrimaryHover, rgba(60, 137, 232, 1))',
                colorFillContentHover:
                  'var(--colorPrimaryHover, rgba(60, 137, 232, 1))',
              },
            },
          }}
        >
          <Slider
            className="main-timeline-slider"
            style={{ margin: 0 }}
            ref={sliderRef}
            styles={{
              track: {
                background:
                  editedFileEpisodeRange.length > 0 || createEpisodeMode
                    ? 'transparent'
                    : 'var(--colorPrimaryHover, rgba(60, 137, 232, 1))!important',
                zIndex: 20,
              },
            }}
            marks={keyFrameMarks}
            disabled={isLoading}
            min={initialMin}
            onChange={(value: any) => {
              refPlayer?.current?.seekTo(value / 1000);
              sliderRef?.current?.blur();
            }}
            onFocus={() => sliderRef?.current?.blur()}
            tooltip={{
              formatter: formatHandleTooltip,
              open: false,
            }}
            value={
              playerState?.playedSeconds !== undefined
                ? playerState?.playedSeconds * 1000
                : 0
            }
            step={0.001}
            max={initialMax}
            classNames={{ handle: 'handleclassy' }}
          ></Slider>
        </ConfigProvider>
        <ConfigProvider
          theme={{
            components: {
              Slider: {
                railBg: 'var(--colorFill)',
                railHoverBg: 'rgba(255, 255, 255, 0.18)',
                railSize: 4,
                trackBg: 'rgba(255, 255, 255, 0.18)',
                handleActiveColor:
                  'var(--colorPrimaryHover, rgba(60, 137, 232, 1))',
                handleColor: 'var(--colorPrimaryHover, rgba(60, 137, 232, 1))',
                handleSizeHover: 0,
                handleSize: 0,
                handleLineWidthHover: 0,
                handleLineWidth: 0,
                colorPrimaryBorderHover: 'rgba(255, 255, 255, 0.18)',
                colorFillContentHover:
                  'var(--colorPrimaryHover, rgba(60, 137, 232, 1))',
              },
            },
          }}
        >
          <Slider
            min={initialMin}
            style={{ margin: 0, pointerEvents: 'none' }}
            className="custom-slider buffering-timeline-slider"
            value={
              playerState?.loadedSeconds !== undefined
                ? playerState?.loadedSeconds * 1000
                : 0
            }
            max={initialMax}
          ></Slider>
        </ConfigProvider>
      </>
    </div>
  );
};
export default VideoPlayerTimeline;
