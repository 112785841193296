import React from 'react';

import { ConfigProvider, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'store';

const PositionsFilter = ({
  positions,
  selectedPosition,
  setSelectedPosition,
}: {
  positions: any[];
  selectedPosition: any;
  setSelectedPosition: any;
}) => {
  const dispatch = useAppDispatch();
  const [t] = useTranslation();

  return (
    <>
      {positions.length > 0 && (
        <ConfigProvider
          theme={{
            token: {
              colorText: 'var(--colorPrimaryBase, #1668DC);',
            },
            components: {
              Select: {
                optionSelectedColor:
                  'var(--colorPrimaryBase, rgba(22, 104, 220, 1))',
              },
            },
          }}
        >
          <Select
            placeholder={
              <div className="select-placeholder">{t('All positions')}</div>
            }
            variant="borderless"
            className={'hasSelection'}
            value={selectedPosition}
            onSelect={(value: string) => {
              if (value === 'clear') {
                dispatch(setSelectedPosition(null));
              } else {
                dispatch(setSelectedPosition(value));
              }
            }}
            popupMatchSelectWidth={false}
            placement="bottomRight"
          >
            <Select.Option
              style={{
                mpaddingTop: 10,
                borderBottom: '0.5px solid rgba(0,0,0,0.1)',
                borderRaius: 0,
                color: 'black',
              }}
              className="enabledClickable"
              key={0}
              onClick={(e: any) => {
                e.preventDefault();
              }}
              label={''}
              value={'clear'}
            >
              {t('Clear filter')}
            </Select.Option>
            {positions.map((position: any, index: number) => (
              <Select.Option
                key={index + 1}
                label={position.value}
                value={position.value}
                style={{
                  color: 'black',
                }}
              >
                {position.label}
              </Select.Option>
            ))}
          </Select>
        </ConfigProvider>
      )}
    </>
  );
};
export default PositionsFilter;
