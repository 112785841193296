import * as React from 'react';
import { FC, ForwardedRef, forwardRef, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  AC_SetActiveVideoAction,
  AC_SetIsPlayingVideoAction,
  AC_SetPlayerHotkeysAllowedAction,
  AC_SetPlayerLoadingAction,
  AC_setPlayerMode,
  AC_SetVisibleRangeAction,
} from 'actions/player.acitons';
import {
  addPlaylistAction,
  getFoldersAction,
} from 'actions/playlistV2.async.actions';
import AddNewFolderNavbarForm from 'components/VideoListController/EpisodesNavbar/AddNewFolderNavbarForm';
import AddNewPlaylistNavbarForm from 'components/VideoListController/EpisodesNavbar/AddNewPlaylistNavbarForm';
import { RenamePlaylistForm } from 'components/VideoListController/EpisodesNavbar/RenamePlaylistForm';
import TopBar from 'components/VideoListController/EpisodesNavbar/TopBar';
import TopBarDetails from 'components/VideoListController/EpisodesNavbar/TopBarDetails';
import { AppStateType } from 'reducers';
import { playerReducerV2 } from 'reducers/player.reducer';
import { playlistReducerV2 } from 'reducers/playlist.reducer';
import { reelsReducer } from 'reducers/reels.reducer';
import { videosListReducer } from 'reducers/videosList.reducer';
import { useAppDispatch } from 'store';
import { NEW_PLAYLIST_TEXT } from 'types/constants';

import { MODES } from '..';
import Filters from '../Filters';
import './index.css';

export const EpisodesNavbar: FC<any> = forwardRef<ForwardedRef<any>, any>(
  (
    {
      constructDowloadLinkRequest,
      resultConfirmationModalHandle,
      sharePlaylistCallable,
      movePlaylistToFolderCallback,
      isShared,
      episodesController,
      setShowPlaylistsSidebar,
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ref,
  ) => {
    const { filteredVideos, playerMode } = useSelector(
      (state: AppStateType) => state.playerReducer,
    );
    const { sharedPlaylist, openedPlaylist } = useSelector(
      (state: AppStateType) => state.playlistReducerV2,
    );
    const dispatch = useAppDispatch();
    const [t] = useTranslation();
    const [renameCurrentPlaylist, setRenameCurrentPlaylist] =
      React.useState(false);
    const getTotalEpisodesCount = () => {
      let total = 0;
      if (playerMode === 'episodes') {
        for (const video of filteredVideos) {
          if (video.events) {
            total += video.events?.length;
          }
        }
      } else if (
        playerMode === 'playlist_view' &&
        !sharedPlaylist &&
        openedPlaylist
      ) {
        for (const video of openedPlaylist.playlist_events) {
          if (video.episodes) {
            total += video.episodes?.length;
          }
        }
      } else if (playerMode === 'playlist_view' && sharedPlaylist) {
        for (const video of sharedPlaylist.playlist_events) {
          if (video.episodes) {
            total += video.episodes?.length;
          }
        }
      }
      return total;
    };
    const [addNewFolder, setAddNewFolder] = React.useState(false);
    const [addNewPlaylist, setAddNewPlaylist] = React.useState(false);
    useEffect(() => {
      if (playerMode === 'playlists') {
        dispatch(getFoldersAction(0));
      } else if (playerMode === MODES.playlist_view) {
        setAddNewFolder(false);
        setAddNewPlaylist(false);
      }
    }, [playerMode, dispatch]);
    const { AC_setPlaylistIsLoading } = playlistReducerV2.actions;
    useEffect(() => {
      if (sharedPlaylist) {
        dispatch(AC_setPlaylistIsLoading(false));
      }
    }, [sharedPlaylist]);
    const inputRefPlaylist = React.useRef(null);
    const [newPlaylistName, setNewPlaylistName] = React.useState<string>(() => {
      if (openedPlaylist) {
        return openedPlaylist.name;
      } else {
        return t(NEW_PLAYLIST_TEXT);
      }
    });
    const addNewPlaylistHanlder = () => {
      if (newPlaylistName) {
        setAddNewPlaylist(false);
        dispatch(addPlaylistAction(newPlaylistName));
      } else {
        setInputStatus('error');
      }
      dispatch(AC_SetPlayerHotkeysAllowedAction(true));
    };
    const closeAddPlaylist = () => {
      setInputStatus('');
      setNewPlaylistName(t(NEW_PLAYLIST_TEXT));
      setAddNewPlaylist(false);
      dispatch(AC_SetPlayerHotkeysAllowedAction(true));
    };
    const inputRef = React.useRef<any>(null);
    const { AC_setOpenedPlaylist, AC_setOpenedPlaylistId } =
      playlistReducerV2.actions;
    const { setPlayedVideoSet, setPlayedEpsiodeAction, setCreateEpisodeMode } =
      playerReducerV2.actions;
    const { setKeyframes, setReelsMode } = reelsReducer.actions;
    const { AC_initialzeSelectedEpisodes } = videosListReducer.actions;
    const { resetEditMode } = playerReducerV2.actions;
    const returnToFolders = () => {
      episodesController.setSelectedEpisodes({});
      dispatch(AC_setOpenedPlaylistId(null));
      dispatch(AC_setOpenedPlaylist(null));
      dispatch(AC_setPlayerMode('playlists'));
      dispatch(AC_SetActiveVideoAction(null));
      dispatch(resetEditMode());
      dispatch(AC_SetIsPlayingVideoAction(false));
      dispatch(setPlayedEpsiodeAction(null));
      dispatch(setPlayedVideoSet(null));
      dispatch(setKeyframes([]));
      dispatch(setReelsMode(false));
      dispatch(AC_SetVisibleRangeAction([]));
      dispatch(setCreateEpisodeMode(false));
      dispatch(AC_initialzeSelectedEpisodes({}));
      dispatch(AC_SetPlayerHotkeysAllowedAction(true));
      dispatch(AC_SetPlayerLoadingAction(false));
    };
    const [inputStatus, setInputStatus] = React.useState<any>('');

    // const [episodesWord, setEpisodesWord] = useState(getEpisodeWord('0'));

    return (
      <>
        <div className="flex-row j-sb ai-c filtersPanelTopBar">
          <div className="filtersPanelTopBar_content">
            <div
              className={`filtersPanelTopBar_content_topLine ${renameCurrentPlaylist ? 'f-ga-4' : ''}`}
            >
              {renameCurrentPlaylist ? (
                <RenamePlaylistForm
                  inputStatus={inputStatus}
                  setInputStatus={setInputStatus}
                  setNewPlaylistName={setNewPlaylistName}
                  openedPlaylist={openedPlaylist}
                  setRenameCurrentPlaylist={setRenameCurrentPlaylist}
                  newPlaylistName={newPlaylistName}
                />
              ) : (
                <TopBar
                  returnToFoldersCallback={returnToFolders}
                  isShared={isShared}
                  setShowPlaylistsSidebar={setShowPlaylistsSidebar}
                  constructDowloadLinkRequest={constructDowloadLinkRequest}
                  resultConfirmationModalHandle={resultConfirmationModalHandle}
                  episodesController={episodesController}
                  getTotalEpisodesCount={getTotalEpisodesCount}
                  sharePlaylistCallable={sharePlaylistCallable}
                  movePlaylistToFolderCallback={movePlaylistToFolderCallback}
                  setRenameCurrentPlaylist={setRenameCurrentPlaylist}
                  setAddNewFolder={setAddNewFolder}
                  addNewFolder={addNewFolder}
                  setAddNewPlaylist={setAddNewPlaylist}
                  addNewPlaylist={addNewPlaylist}
                  inputRefPlaylist={inputRefPlaylist}
                  inputRef={inputRef}
                />
              )}
            </div>
            <div className="filtersPanelTopBar_content_matchLine">
              <TopBarDetails getTotalEpisodesCount={getTotalEpisodesCount} />
              {addNewFolder && (
                <AddNewFolderNavbarForm
                  inputStatus={inputStatus}
                  setInputStatus={setInputStatus}
                  inputRef={inputRef}
                  setAddNewFolder={setAddNewFolder}
                />
              )}
              {addNewPlaylist && (
                <AddNewPlaylistNavbarForm
                  inputRefPlaylist={inputRefPlaylist}
                  inputStatus={inputStatus}
                  setInputStatus={setInputStatus}
                  newPlaylistName={newPlaylistName}
                  setNewPlaylistName={setNewPlaylistName}
                  addNewPlaylistHanlder={addNewPlaylistHanlder}
                  closeAddPlaylist={closeAddPlaylist}
                />
              )}
            </div>
          </div>
        </div>
        {!isShared && (
          <Filters
            setSelectedEpisodes={episodesController.setSelectedEpisodes}
          />
        )}
      </>
    );
  },
);
