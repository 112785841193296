import React, { useState } from 'react';

import { Flex, Skeleton } from 'antd';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { AppStateType } from 'reducers';

import { ReactComponent as DownOutlinedBlack } from '../../../../assets/img/icons/faDownOutlinedBlack.svg';
import AverageStatValueTag from '../AverageStatValueTag';
import ConsolidatedStatValueTag from '../ConsolidatedStatValueTag';
import { makeLink } from '../const';
import MetricFilter from '../MetricFilter';

const StatisticsDetailsBlock = ({
  selectedMetricFilters,
  // selectedSeason,
  setSelectedMetricFilters,
}: {
  selectedMetricFilters: any;
  selectedSeason: any;
  setSelectedMetricFilters: any;
}) => {
  const { playerId } = useParams();
  const {
    statsState,
    teamPlayerStats,
    timeOnField,
    selectedRowKeys,
    timeOnFieldByPosition,
  } = useSelector((state: AppStateType) => state.teamPlayerStatsReducer);
  const { openedTeamPlayer, selectedPosition } = useSelector(
    (state: AppStateType) => state.teamPlayerReducer,
  );
  const [expandedStats, setExpandedStats] = useState<string[]>([]);
  const [hoveredMetricFilter, setHoveredMetricFilters] = useState<string[]>([]);
  const showAveragesTag = openedTeamPlayer && openedTeamPlayer.team.sport !== 2;

  const makeLinkAndNavigate = (stat: any) => {
    const metricStringSuccess = `${stat.key}_success`;
    const metricStringFail = `${stat.key}_fail`;
    let finalMetric = [stat];
    if (
      selectedMetricFilters &&
      selectedMetricFilters.includes(metricStringSuccess)
    ) {
      finalMetric = [{ key: metricStringSuccess }];
    } else if (
      selectedMetricFilters &&
      selectedMetricFilters.includes(metricStringFail)
    ) {
      finalMetric = [{ key: metricStringFail }];
    }
    const link = makeLink(
      [{ id: null }],
      openedTeamPlayer.team,
      finalMetric,
      undefined,
      '',
      playerId,
      selectedPosition,
    );
    localStorage.setItem(
      `player_matches_${link}`,
      JSON.stringify(selectedRowKeys),
    );
    window.open(link, '_blank');
  };
  const toggleSelectedMetric = (
    metricString: string,
    removedMetricString: string,
  ) => {
    if (selectedMetricFilters.includes(metricString)) {
      if (selectedMetricFilters.length === 1) {
        setSelectedMetricFilters([]);
      } else if (selectedMetricFilters.length > 1) {
        setSelectedMetricFilters((prev: any) =>
          prev.filter((el: any) => el !== metricString),
        );
      }
    } else {
      if (selectedMetricFilters.length === 0) {
        setSelectedMetricFilters([metricString]);
      } else {
        setSelectedMetricFilters((prev: any) => [
          ...prev.filter((p: any) => p !== removedMetricString),
          metricString,
        ]);
      }
    }
  };
  const showStat = (stat: any) => {
    return (
      openedTeamPlayer &&
      (stat.for_sport?.length === 0 ||
        (stat.for_sport?.length &&
          stat.for_sport.includes(openedTeamPlayer?.team.sport))) &&
      !stat.excluded_sports?.includes(openedTeamPlayer?.team.sport)
    );
  };
  const onMousEnter = (e: any, stat: any) => {
    e.preventDefault();
    if (stat.stat?.percent !== null && stat.stat?.percent !== undefined) {
      setHoveredMetricFilters((prev) => [...prev, stat.key]);
    }
  };
  return (
    <Flex vertical style={{ margin: '0 16px 8px 16px', alignSelf: 'stretch' }}>
      {teamPlayerStats
        ?.filter((stat: any) => showStat(stat))
        ?.map((stat: any) => {
          return (
            <>
              <Flex
                justify="flex-start"
                style={{ width: '100%', margin: '2px 0px' }}
              >
                <Flex
                  justify="space-between"
                  style={{ width: '100%' }}
                  align="center"
                  gap={8}
                  onMouseEnter={(e: any) => {
                    onMousEnter(e, stat);
                  }}
                  onMouseLeave={() => {
                    setHoveredMetricFilters((prev) =>
                      prev.filter((el: string) => el !== stat.key),
                    );
                  }}
                >
                  <Flex
                    style={{
                      marginLeft: 0,
                      overflow: 'hidden',
                    }}
                    align="center"
                    justify="flex-start"
                    gap={8}
                    flex={1}
                    className={`${stat.children?.length ? 'enabledClickable' : ''}`}
                    onClick={() => {
                      if (
                        stat.children?.length &&
                        expandedStats.includes(stat.key)
                      ) {
                        setExpandedStats((prev) =>
                          prev.filter((item: string) => item !== stat.key),
                        );
                      } else {
                        setExpandedStats((prev) => [...prev, stat.key]);
                      }
                    }}
                  >
                    {stat.label}
                    {stat.children?.length && (
                      <DownOutlinedBlack
                        className={`enabledClickable ${expandedStats.includes(stat.key) ? 'rotate180' : 'rotate90'}`}
                      />
                    )}
                  </Flex>
                  <Flex>
                    <MetricFilter
                      metric={stat.key}
                      selectedMetricFilters={selectedMetricFilters}
                      hoveredMetricFilter={hoveredMetricFilter}
                      toggleSelectedMetric={toggleSelectedMetric}
                    />
                  </Flex>
                  <Flex gap={8}>
                    {statsState === 'loading' || statsState === 'init' ? (
                      <div style={{ borderRadius: 8, overflow: 'hidden' }}>
                        <Skeleton.Button
                          active
                          style={{ minWidth: 130, minHeight: 38 }}
                        />
                      </div>
                    ) : (
                      <ConsolidatedStatValueTag
                        showPth={
                          openedTeamPlayer && openedTeamPlayer.team.sport !== 2
                        }
                        stat={stat.stat || stat}
                        makeLink={makeLinkAndNavigate}
                        loadingPercentiles={false}
                        selectedMetricFilters={selectedMetricFilters}
                        selectedPositions={selectedPosition}
                      />
                    )}
                    {openedTeamPlayer &&
                      openedTeamPlayer.team.sport !== 2 &&
                      (statsState === 'loading' || statsState === 'init' ? (
                        <div style={{ borderRadius: 8, overflow: 'hidden' }}>
                          <Skeleton.Button
                            active
                            style={{ minWidth: 56, minHeight: 38 }}
                          />
                        </div>
                      ) : (
                        <AverageStatValueTag
                          stat={stat.stat}
                          loadingPercentiles={false}
                          selectedMetricFilters={selectedMetricFilters}
                          selectedPositions={selectedPosition}
                          timeOnField={timeOnField}
                          timeOnFieldByPosition={
                            selectedPosition &&
                            timeOnFieldByPosition[selectedPosition]
                          }
                        />
                      ))}
                  </Flex>
                </Flex>
              </Flex>
              {stat.children?.length > 0 && (
                <Flex
                  vertical
                  style={{ width: '100%' }}
                  gap={8}
                  className={`${expandedStats.includes(stat.key) ? 'expanded' : 'collapsed'}`}
                >
                  {stat.children
                    .filter((child: any) => showStat(child))
                    .map((child: any, index: number) => {
                      return statsState === 'loading' ? (
                        <Skeleton.Button
                          key={index}
                          active
                          shape={'round'}
                          style={{ width: 75, height: 32 }}
                        />
                      ) : (
                        <Flex
                          key={index}
                          justify="space-between"
                          style={{ width: '100%' }}
                          gap={8}
                        >
                          <Flex
                            style={{
                              marginLeft: 20,
                              overflow: 'hidden',
                            }}
                            align="center"
                            justify="space-between"
                            gap={8}
                            flex={1}
                            onMouseEnter={(e: any) => {
                              onMousEnter(e, child);
                            }}
                            onMouseLeave={() => {
                              setHoveredMetricFilters((prev) =>
                                prev.filter((el: string) => el !== child.key),
                              );
                            }}
                          >
                            <Flex>{child.label}</Flex>
                            <MetricFilter
                              metric={child.key}
                              selectedMetricFilters={selectedMetricFilters}
                              hoveredMetricFilter={hoveredMetricFilter}
                              toggleSelectedMetric={toggleSelectedMetric}
                            />
                          </Flex>
                          <Flex gap={8}>
                            <ConsolidatedStatValueTag
                              showPth={
                                openedTeamPlayer &&
                                openedTeamPlayer.team.sport !== 2
                              }
                              stat={child.stat}
                              loadingPercentiles={false}
                              selectedMetricFilters={selectedMetricFilters}
                              makeLink={makeLinkAndNavigate}
                              selectedPositions={selectedPosition}
                            />
                            {showAveragesTag && (
                              <AverageStatValueTag
                                stat={child.stat}
                                loadingPercentiles={false}
                                selectedMetricFilters={selectedMetricFilters}
                                selectedPositions={selectedPosition}
                                timeOnField={timeOnField}
                                timeOnFieldByPosition={
                                  selectedPosition &&
                                  timeOnFieldByPosition[selectedPosition]
                                }
                              />
                            )}
                          </Flex>
                        </Flex>
                      );
                    })}
                </Flex>
              )}
            </>
          );
        })}
    </Flex>
  );
};
export default StatisticsDetailsBlock;
