import React, { FC, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom';

import './App.css';

import { NotificationsAPI } from 'api/notifications';
import { resources } from 'i18n';
import CalendarPage from 'pages/CalendarPage';
import ErrorPage from 'pages/ErrorPage';
import LoginPage from 'pages/LoginPage';
import PlayerPage from 'pages/PlayerPage';
import PlaylistsPage from 'pages/PlaylistsPage/PlaylistsPage';
import RecoverPasswordPage from 'pages/RecoverPasswordPage';
import RegistrationPage from 'pages/RegistrationPage';
import ResetPasswordPage from 'pages/ResetPasswordPage';
import SharedPlaylistPage from 'pages/SharedPlaylistPage';
import TeamPage from 'pages/TeamPage';
import TournamentPage from 'pages/TournamentPage';
import VideoDownloadsPage from 'pages/VideoDownloadsPage';
import VideoPlayerPage from 'pages/VideoPlayerPage';
import { AppStateType } from 'reducers';
import { notificationsReducer } from 'reducers/notifications.reducer';
import { useAppDispatch } from 'store';

const ProtectedRoute: FC<any> = ({ children }) => {
  const localtoken =
    typeof window !== 'undefined' &&
    window.localStorage &&
    localStorage.getItem('authtoken');
  if (!localtoken) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path="/"
        element={
          <ProtectedRoute user={true}>
            <CalendarPage />
          </ProtectedRoute>
        }
        errorElement={<ErrorPage />}
      />
      <Route
        path="/downloads/:downloadId"
        element={
          <ProtectedRoute user={true}>
            <VideoDownloadsPage />
          </ProtectedRoute>
        }
        errorElement={<ErrorPage />}
      />
      <Route
        path="/player/playlists"
        element={
          <ProtectedRoute user={true}>
            <PlaylistsPage />
          </ProtectedRoute>
        }
        errorElement={<ErrorPage />}
      />
      <Route
        path="/player"
        element={
          <ProtectedRoute user={true}>
            <VideoPlayerPage />
          </ProtectedRoute>
        }
        errorElement={<ErrorPage />}
      />
      <Route
        path="/downloads"
        element={
          <ProtectedRoute user={true}>
            <VideoDownloadsPage />
          </ProtectedRoute>
        }
        errorElement={<ErrorPage />}
      />
      <Route
        path="/teams/:teamId"
        element={
          <ProtectedRoute user={true}>
            <TeamPage />
          </ProtectedRoute>
        }
        errorElement={<ErrorPage />}
      />
      <Route
        path="/tournaments/:tournamentId"
        element={
          <ProtectedRoute user={true}>
            <TournamentPage />
          </ProtectedRoute>
        }
        errorElement={<ErrorPage />}
      />
      <Route
        path="/players/:playerId"
        element={
          <ProtectedRoute user={true}>
            <PlayerPage />
          </ProtectedRoute>
        }
        errorElement={<ErrorPage />}
      />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/join" element={<RegistrationPage />} />
      <Route path="/join/:linkId" element={<RegistrationPage />} />
      <Route path="/recover-password" element={<RecoverPasswordPage />} />
      <Route path="/reset-password" element={<ResetPasswordPage />} />
      <Route path="/share/:shareLink" element={<SharedPlaylistPage />} />
    </>,
  ),
);

function App() {
  const [t] = useTranslation();

  const dispatch = useAppDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { setNotifications } = notificationsReducer.actions;
  const { notificatons } = useSelector(
    (state: AppStateType) => state.notificationsReducer,
  );
  const { showNotification } = notificationsReducer.actions;
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const fetchNotifications = async () => {
      const response = await NotificationsAPI.getUnreadNotifications();
      const newNotifications = response.data.filter(
        (notification: any) => !notification.is_notified,
      );
      // dispatch(setNotifications(response.data));
      newNotifications.forEach((n: any) => {
        let body: string;
        const dynamicMatch = n.body.match('(.*)({+)(.*)(}+)(.*)');
        if (!dynamicMatch) {
          body = t(n.body); // Return the input string if no dynamic part is found
        } else {
          const dynamicPart = dynamicMatch[3];
          const regex = new RegExp(dynamicMatch[1] + '({}+)' + dynamicMatch[5]);
          const translationKey = Object.keys(resources.ru.translation).find(
            (key) => {
              const match = key.match(regex);
              // console.log(match);
              if (match) {
                return match[1];
              }
            },
          );
          if (!translationKey) {
            body = t(n.body); // Return the input string if no matching key is found
          } else {
            const match = translationKey.match(regex);
            let translatedValue = t(translationKey);
            // @ts-ignore
            translatedValue = translatedValue.replace(match[1], dynamicPart);
            body = translatedValue;
          }
        }
        dispatch(
          showNotification({
            notificationParameters: [
              {
                callbackName:
                  n.title === 'success' ? 'downloadFile' : 'openDownloads',
                url: n.url,
                id: n.id,
                type: n.title,
                text: body, // TODO
              },
            ],
          }),
        );
      });
    };
    // const timeout = setTimeout(async () => await fetchNotifications(), 5000);
    // return () => clearTimeout(timeout);
  }, [notificatons]);
  return <RouterProvider router={router} />;
}

export default App;
