/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';

import { Tooltip as ATooltip, Divider, Flex, Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import './index.css';

import {
  GetPersonaAllMatches,
  SetSelectedLastMatch,
  SetStatsState,
} from 'actions/persona.actions';
import { EventsAPI } from 'api/events';
import { AppStateType } from 'reducers';
import { teamPlayerReducer } from 'reducers/teamPlayer.reducer';
import { teamPlayerStatsReducer } from 'reducers/teamPlayerStats.reducer';
import { useAppDispatch } from 'store';

import { makeStatsPlaceholders } from './const';
import PositionsFilter from './PositionsFilter';
import { PlayerStatisticsContent } from './SettingPopover';
import StatisticsDetailsBlock from './StatisticsDetailsBlock';
import { constructMatchesString } from './utils';

const PlayerStatisticsBlock = () => {
  const [t] = useTranslation();
  const STATS_PLACEHOLDER = makeStatsPlaceholders(t);
  const { playerId } = useParams();
  const {
    openedTeamPlayer,
    selectedMatchesStats,
    positions,
    positionsDurations,
    selectedPosition,
  } = useSelector((state: AppStateType) => state.teamPlayerReducer);
  const {
    selectedTournaments,
    teamPlayerStatsMatches,
    appliedLastMatch,
    statsState,
    teamPlayerStats,
    selectedRowKeys,
  } = useSelector((state: AppStateType) => state.teamPlayerStatsReducer);
  const {
    setTeamPlayerStats,
    setAppliedSeasons,
    setSelectedTournaments,
    setTimeOnField,
    setTimeOnFieldByPosition,
    setCheckedSeasons,
  } = teamPlayerStatsReducer.actions;
  const { setSelectedPosition } = teamPlayerReducer.actions;
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [selectedSeason, setSelectedSeason] = useState<string | number>();
  const [selectedSeasonLabel, setSelectedSeasonLabel] = useState<string>();
  const [selectedMetricFilters, setSelectedMetricFilters] = useState<string[]>(
    [],
  );
  // const [playerStats, setPlayerStats] = useState<any[]>(STATS_PLACEHOLDER);
  const [isLong, setIsLong] = useState<boolean>(false);
  const PopoverHandlerRef = React.createRef<any>();
  const {
    selectedLastMatch,
    selectedMatches,
    appliedSeasons,
    appliedTournaments,
  } = useSelector((state: AppStateType) => state.teamPlayerStatsReducer);
  const playerIdRef = useRef<any>(null);
  useEffect(() => {
    dispatch(setTeamPlayerStats(makeStatsPlaceholders(t)));
  }, []);
  useEffect(() => {
    // Инициализация
    if (playerId !== playerIdRef.current && playerId) {
      playerIdRef.current = playerId;
      dispatch(GetPersonaAllMatches(playerId));
    }
  }, [playerId]);

  const getSeasonStats = async (seasonId: string | number) => {
    const response = await EventsAPI.getPlayerGamesStatsByPost(
      seasonId.toString(),
      playerId || '',
      selectedPosition || '',
      undefined,
      selectedRowKeys,
      selectedPosition
        ? positionsDurations[selectedPosition] > 90 * 60 * 1000
        : false,
    );
    const finalStats: any = [];
    dispatch(setTimeOnField(response.data.time_on_field));
    dispatch(
      setTimeOnFieldByPosition(
        response.data.stats.filter((s: any) => {
          return s.key === 'played_time';
        })[0]?.by_position,
      ),
    );
    teamPlayerStats.forEach((stat) => {
      const populatedStat: any = {
        label: stat.label,
        key: stat.key,
        excluded_sports: stat.excluded_sports,
        for_sport: stat.for_sport,
        stat: response.data.stats.filter((s: any) => s.key === stat?.key)[0],
      };
      if (stat.children?.length > 0) {
        populatedStat.children = [];
        stat.children.forEach((childStat: any) => {
          const childPopulatedStat = {
            ...childStat,
            excluded_sports: childStat.excluded_sports,
            for_sport: childStat.for_sport,
            stat: response.data.stats.filter(
              (s: any) => s.key === childStat.key,
            )[0],
          };
          populatedStat.children.push(childPopulatedStat);
        });
      }
      finalStats.push(populatedStat);
    });
    dispatch(setTeamPlayerStats(finalStats));
  };
  // const getSeasonPercentiles = async (seasonId: string, cancelToken: any) => {
  //   dispatch(SetStatsState('loading'));
  //   const response = await EventsAPI.getPlayerPercentilesBySeason(
  //     seasonId,
  //     playerId || '',
  //     selectedPosition || '',
  //     selectedMatchesStats,
  //     selectedPosition
  //       ? positionsDurations[selectedPosition] > 90 * 60 * 1000
  //       : false,
  //     cancelToken,
  //   );
  //   const finalStats: any = [];
  //   if (response?.data) {
  //     playerStats.forEach((stat) => {
  //       const populatedStat: any = {
  //         ...stat,
  //         status: 'loaded',
  //         stat: {
  //           ...stat.stat,
  //           pth: response.data.stats.filter((s: any) => s.key === stat?.key)[0],
  //         },
  //       };
  //       if (stat.children?.length > 0) {
  //         populatedStat.children = [];
  //         stat.children.forEach((childStat: any) => {
  //           const s = response.data.stats.filter(
  //             (s: any) => s.key === childStat.key,
  //           )[0];
  //           const childPopulatedStat = {
  //             ...childStat,
  //             status: 'loaded',
  //             stat: {
  //               ...childStat,
  //               pth: s.pth,
  //             },
  //           };
  //           populatedStat.children.push(childPopulatedStat);
  //         });
  //       }
  //       finalStats.push(populatedStat);
  //     });
  //     setPlayerStats(finalStats);
  //   }
  //   dispatch(SetStatsState('loaded'));
  // };
  useEffect(() => {
    // if (openedTeamPlayer) {
    if (
      selectedSeason &&
      selectedSeason !== 'manual' &&
      selectedRowKeys &&
      selectedRowKeys.length === 0
    ) {
      dispatch(SetStatsState('loading'));
      getSeasonStats(selectedSeason).then(() =>
        dispatch(SetStatsState('loaded')),
      );
    } else if (
      selectedSeason &&
      selectedRowKeys &&
      selectedRowKeys.length > 0
    ) {
      dispatch(SetStatsState('loading'));
      getSeasonStats('manual').then(() => dispatch(SetStatsState('loaded')));
    } else if (statsState === 'loading') {
      const finalStats: any = [];
      STATS_PLACEHOLDER.forEach((stat: any) => {
        const populatedStat: any = {
          label: stat.label,
          key: stat.key,
          stat: null,
          excluded_sports: stat.excluded_sports,
          for_sport: stat.for_sport,
        };
        if (stat.children?.length > 0) {
          populatedStat.children = [];
          stat.children.forEach((childStat: any) => {
            const childPopulatedStat = {
              ...childStat,
              excluded_sports: childStat.excluded_sports,
              for_sport: childStat.for_sport,
              stat: null,
            };
            populatedStat.children.push(childPopulatedStat);
          });
        }
        finalStats.push(populatedStat);
      });
      dispatch(setTeamPlayerStats(finalStats));
      dispatch(SetStatsState('loaded'));
    }
    // }
  }, [selectedSeason]);
  // useEffect(() => {
  //   // eslint-disable-next-line
  //   if (
  //     playerId &&
  //     selectedSeason &&
  //     selectedSeason !== 'manual' &&
  //     selectedMatchesStats.length === 0
  //   ) {
  //     // clearPercentiles();
  //     if (
  //       selectedPosition &&
  //       positionsDurations[selectedPosition] > 90 * 60 * 1000
  //     ) {
  //       const source = axios.CancelToken.source();
  //       getSeasonPercentiles(selectedSeason.toString(), source.token).then();
  //       return () => {
  //         source.cancel('Operation canceled by the user.');
  //       };
  //     }
  //   }
  // }, [selectedPosition]);
  useEffect(() => {
    if (teamPlayerStatsMatches.length) {
      setSelectedSeason(teamPlayerStatsMatches[0].leagues[0]?.season_id);
      dispatch(setAppliedSeasons([teamPlayerStatsMatches[0]?.name]));
      setSelectedSeasonLabel(teamPlayerStatsMatches[0].name);
      const firstSeason =
        teamPlayerStatsMatches.length > 0
          ? `${teamPlayerStatsMatches[0].name}`
          : '';
      if (firstSeason && !selectedLastMatch) {
        // dispatch(
        //   setCheckedSeasons([
        //     `1-${appliedSeasons[0] || firstSeason}-${
        //       appliedTournaments[0] ||
        //       teamPlayerStatsMatches[0]?.leagues[0].tournament_id
        //     }`,
        //   ]),
        // );
        // dispatch(
        //   setSelectedTournaments([
        //     `1-${appliedSeasons[0] || firstSeason}-${
        //       appliedTournaments[0] ||
        //       teamPlayerStatsMatches[0]?.leagues[0].tournament_id
        //     }`,
        //   ]),
        // );
      }
    }
    const checkContentHeight = () => {
      if (PopoverHandlerRef.current) {
        setIsLong(PopoverHandlerRef.current.scrollWidth > 180);
      }
    };
    checkContentHeight();
  }, [teamPlayerStatsMatches]);
  // const clearPercentiles = () => {
  //   const finalStats: any = [];
  //   playerStats.forEach((stat) => {
  //     const populatedStat: any = {
  //       ...stat,
  //       status: 'loaded',
  //       stat: {
  //         ...stat.stat,
  //         pth: null,
  //       },
  //     };
  //     if (stat.children?.length > 0) {
  //       populatedStat.children = [];
  //       stat.children.forEach((childStat: any) => {
  //         const childPopulatedStat = {
  //           ...childStat,
  //           status: 'loaded',
  //           stat: {
  //             ...childStat,
  //             pth: null,
  //           },
  //         };
  //         populatedStat.children.push(childPopulatedStat);
  //       });
  //     }
  //     finalStats.push(populatedStat);
  //   });
  //   setPlayerStats(finalStats);
  // };

  const hide = () => {
    setOpen((prev) => false);
  };
  const handleOpenChange = (newOpen: boolean) => {
    setOpen((prev) => newOpen);
  };
  return (
    <div className="block-wrapper right">
      <Popover
        placement="left"
        content={
          <PlayerStatisticsContent
            hide={hide}
            isOpen={open}
            firstSeason={
              teamPlayerStatsMatches.length > 0
                ? `${teamPlayerStatsMatches[0].name}`
                : ''
            }
          />
        }
        open={open}
        className="some-class"
        trigger="contextMenu"
        onOpenChange={handleOpenChange}
      >
        <Flex className="block-title" align="center">
          <Flex gap={8} align="center" flex={1} justify="flex-start">
            <div className="block-title-h">{t('Statistics')}</div>
            <div
              ref={PopoverHandlerRef}
              className="playerStatsPopoverHandler"
              style={{ color: 'var(--colorTextLabel, rgba(0, 0, 0, 0.65))' }}
              onClick={() => {
                setOpen((prev: boolean) => true);
                dispatch(SetSelectedLastMatch(appliedLastMatch));
              }}
            >
              {isLong && (
                <ATooltip
                  title={
                    teamPlayerStatsMatches.filter(
                      (s: any) => s.name === selectedSeasonLabel,
                    )[0]?.name
                  }
                  arrow={true}
                >
                  <div>
                    {
                      teamPlayerStatsMatches.filter(
                        (s: any) => s.name === selectedSeasonLabel,
                      )[0]?.name
                    }
                  </div>
                </ATooltip>
              )}
              {!isLong && (
                <div>
                  {selectedTournaments.length === 1
                    ? selectedTournaments[0].split('-')[1]
                    : ''}
                  &nbsp;
                  {constructMatchesString({
                    selectedRowKeys,
                    t,
                    selectedTournaments,
                  })}
                </div>
              )}
            </div>
          </Flex>
          <Flex align="center" gap={8}>
            <PositionsFilter
              positions={
                openedTeamPlayer
                  ? openedTeamPlayer.team.sport !== 2
                    ? positions
                    : []
                  : []
              }
              selectedPosition={selectedPosition}
              setSelectedPosition={setSelectedPosition}
            />
          </Flex>
        </Flex>
      </Popover>
      <div className="block-content">
        <div className="block-column block-colum-player-stats">
          <Flex className="block-column-title" justify="space-between">
            <Flex flex={1} className="stats-name">
              {t('Actions')}
            </Flex>
            <Flex
              flex={
                openedTeamPlayer && openedTeamPlayer.team.sport === 2
                  ? 'unset'
                  : 1
              }
              justify="space-between"
            >
              <div
                className="stats-header"
                style={{
                  minWidth: 130,
                  flex:
                    openedTeamPlayer && openedTeamPlayer.team.sport === 2
                      ? 'unset'
                      : 1,
                  textAlign: 'start',
                }}
              >
                {t('Total')}
              </div>
              {openedTeamPlayer && openedTeamPlayer.team.sport !== 2 ? (
                <div className="stats-header">{t('90 min')}</div>
              ) : (
                <div></div>
              )}
            </Flex>
          </Flex>
          <Divider style={{ margin: '0 0 8px 0', width: 'auto' }} />
          <StatisticsDetailsBlock
            selectedMetricFilters={selectedMetricFilters}
            selectedSeason={selectedSeason}
            setSelectedMetricFilters={setSelectedMetricFilters}
          />
        </div>
      </div>
    </div>
  );
};
export default PlayerStatisticsBlock;
