import { createSlice } from '@reduxjs/toolkit';

type InitialStateType = {
  openedGameId: string | null;
  statsCustomGameList: string[];
  expandedGameClickedId: any;
  selectedSeason: any;
  selectedBestPlayerSeason: any;
  selectedMatchesStats: any[];
  selectedMatchesBestPlayers: any[];
  showGameSelectionModal: boolean;
  manualGamesSelectionSection: string;
  bestPlayerStat: string;
  expandedGames: string[];
};
const initialState: InitialStateType = {
  openedGameId: null,
  statsCustomGameList: [],
  expandedGameClickedId: null,
  selectedSeason: null,
  selectedBestPlayerSeason: null,
  selectedMatchesStats: [],
  selectedMatchesBestPlayers: [],
  showGameSelectionModal: false,
  manualGamesSelectionSection: '',
  bestPlayerStat: 'goals',
  expandedGames: [],
};

export const teamReducer = createSlice({
  name: 'teamReducer',
  initialState: initialState,
  reducers: {
    setBestPlayersStat(state, action) {
      state.bestPlayerStat = action.payload;
    },
    setManualGamesSelectionSection(state, action) {
      state.manualGamesSelectionSection = action.payload;
    },
    setShowGameSelectionModal(state, action) {
      state.showGameSelectionModal = action.payload;
    },
    setSelectedMatchesStats(state, action) {
      state.selectedMatchesStats = action.payload;
    },
    setSelectedMatchesBestPlayers(state, action) {
      state.selectedMatchesBestPlayers = action.payload;
    },
    setSelectedSeason(state, action) {
      state.selectedSeason = action.payload;
    },
    setSelectedBestPlayerSeason(state, action) {
      state.selectedBestPlayerSeason = action.payload;
    },
    setGameProtocolId(state, action) {
      state.openedGameId = action.payload;
    },
    setStatsCustomGameList(state, action) {
      state.statsCustomGameList = action.payload;
    },
    setExpandedGameClickedId(state, action) {
      state.expandedGameClickedId = {
        id: action.payload.id,
        expanded: action.payload.expanded,
      };
    },
    setExpandedGamesTeam(state, action) {
      state.expandedGames = action.payload;
    },
    clearExpandedGamesTeam(state) {
      state.expandedGames = [];
    },
  },
});
