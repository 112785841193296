import React, { useEffect, useRef, useState } from 'react';

import { useSortable } from '@dnd-kit/sortable';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CSS } from '@dnd-kit/utilities';
import { Button, ConfigProvider, Input, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { AC_SetPlayerHotkeysAllowedAction } from 'actions/player.acitons';
import { NEW_FOLDER } from 'types/constants';

import {
  getRootPlaylistsActionV2,
  updateFolderAction,
} from '../../actions/playlistV2.async.actions';
import { ReactComponent as CheckboxFilled } from '../../assets/img/icons/faCheckboxFilled.svg';
import { ReactComponent as CloseCross } from '../../assets/img/icons/faCloseCrossWhite.svg';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ReactComponent as FolderIcon } from '../../assets/img/icons/faFolderIcon.svg';
import FolderContext from '../../components/contexts/FolderContext/index';
import { useAppDispatch } from '../../store';

const TitleWithTooltip = ({ folder }: { folder: any }) => {
  const ref = useRef<any>(null);
  const isOverflowing =
    (ref?.current?.scrollWidth || 0) > (ref?.current?.clientWidth || 0);
  return (
    <Tooltip
      color="var(--tooltip-75)"
      overlayStyle={{
        border:
          '1px solid var(--character-disabled-amp-placeholder-25, rgba(255, 255, 255, 0.30))',
        borderRadius: '8px',
      }}
      title={isOverflowing ? folder.title : ''}
      placement="bottom"
      arrow={false}
    >
      <div
        ref={ref}
        className={'flex-row folder-name'}
        style={{ flex: 1, width: 200 }}
      >
        <div className="elipsis-text">{folder.title}</div>
      </div>
    </Tooltip>
  );
};

const FolderElement = ({
  folder,
  setDeleteFolderId,
  moveToFolderCallback,
  shareEpisodesCallable,
}: {
  folder: any;
  setDeleteFolderId: any;
  moveToFolderCallback: any;
  shareEpisodesCallable: any;
}) => {
  const [renameFolderId, setRenameFolderId] = useState(null);
  const inputRef = React.useRef(null);
  const [newFolderName, setNewFolderName] = useState(folder.title);
  const [expanded, setExpanded] = useState<string[]>([]);
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: folder.id });
  const dispatch = useAppDispatch();
  const [inpustStatus, setInputStatus] = useState<any>('');
  const renameFolderHandler = (folder: any) => {
    if (newFolderName) {
      const updatedFolder = {
        ...folder,
        name: newFolderName,
      };
      setRenameFolderId(null);
      dispatch(AC_SetPlayerHotkeysAllowedAction(true));
      dispatch(updateFolderAction(updatedFolder, folder.key, true));
    } else {
      setInputStatus('error');
    }
  };
  useEffect(() => {
    if (renameFolderId === folder.elId) {
      setNewFolderName(folder.title);
    }
  }, [renameFolderId]);
  const toggleFolder = (folder: any) => {
    // TODO сохранить текущую сортировку элементов
    const updatedFolder = {
      ...folder,
      collapsed: !folder.collapsed,
    };
    // console.log('asdsad')
    dispatch(updateFolderAction(updatedFolder, folder.elId));
    dispatch(getRootPlaylistsActionV2());
    if (!showDiv) {
      setShowDiv(true);
    }
    if (expanded.includes(folder.elId)) {
      setExpanded((prev) => prev.filter((el) => el !== folder.elId));
    } else {
      setExpanded((prev) => [...prev, folder.elId]);
    }
  };
  const [showDiv, setShowDiv] = useState(true);
  const getStyle = () => {
    return {
      transition,
      transform: CSS.Transform.toString(transform),
    };
  };

  const [t] = useTranslation();
  return (
    <div
      ref={setNodeRef}
      style={getStyle()}
      className={'folder-row-wrapper '}
      {...attributes}
      {...listeners}
    >
      <div
        className="flex-row enabledClickable ai-c f-ga-6"
        style={{ flex: 1 }}
      >
        {renameFolderId === folder.elId ? (
          <ConfigProvider>
            <Input
              ref={inputRef}
              status={inpustStatus}
              className="new-folder-input"
              type="text"
              name="newFolderName"
              onClick={(e) => {
                e.stopPropagation();
              }}
              onChange={(event: any) => {
                setInputStatus('');
                setNewFolderName(event.target.value);
              }}
              autoFocus={true}
              defaultValue={folder.title}
              placeholder={t(NEW_FOLDER)}
              maxLength={100}
              style={{
                background: 'var(--colorBgContainer)',
                color: 'var(--colorText)',
              }}
            ></Input>
            <Button
              style={{
                border: '1px solid var(--colorIcon)',
                background: 'transparent',
                height: 24,
                width: 32,
              }}
              onClick={(e) => {
                e.stopPropagation();
                setNewFolderName(null);
                setInputStatus('');
                setRenameFolderId(null);
                dispatch(AC_SetPlayerHotkeysAllowedAction(true));
              }}
              icon={<CloseCross />}
            />
            <Button
              style={{
                height: 24,
                width: 32,
                border: '1px solid var(--colorPrimaryBase)',
                background: 'rgba(22, 104, 220, 0.4)',
              }}
              onClick={(e) => {
                e.stopPropagation();
                renameFolderHandler(folder);
                dispatch(AC_SetPlayerHotkeysAllowedAction(true));
              }}
              icon={<CheckboxFilled />}
              type="default"
            />
          </ConfigProvider>
        ) : (
          <>
            <div
              className="flex-row enabledClickable ai-c f-ga-6 oy-h"
              style={{ flex: 1 }}
              onClick={() => toggleFolder(folder)}
            >
              <div className="flex-row ai-c j-ce" style={{ width: 24 }}>
                <FolderIcon />
              </div>
              <TitleWithTooltip folder={folder} />
              <div className="flex-row ai-c j-ce">
                <div className="elements-count">
                  {folder.playlist_count > 0 ? folder.playlist_count : ''}
                </div>
              </div>
            </div>
            <FolderContext
              shareCallback={shareEpisodesCallable}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              downloadCallback={() => {}}
              renameCallback={setRenameFolderId}
              deleteCallback={setDeleteFolderId}
              folder={folder}
              moveToFolderCallback={moveToFolderCallback}
            />
          </>
        )}
      </div>
    </div>
  );
};
export default FolderElement;
