import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { AppStateType } from 'reducers';
import { teamReducer } from 'reducers/team.reducer';

import { ReactComponent as PlayCircle } from '../../../../assets/img/icons/faPlayCircle.svg';

const StatValueTag = ({
  stat,
  match,
  expanded,
  makeLink,
  selectedMetricFilters,
}: {
  stat: any;
  match: any;
  expanded: any;
  makeLink: any;
  selectedMetricFilters: any;
}) => {
  const dispatch = useDispatch();
  const { setExpandedGameClickedId } = teamReducer.actions;
  const { openedTeamPlayer } = useSelector(
    (state: AppStateType) => state.teamPlayerReducer,
  );
  const [t] = useTranslation();
  const [displayedValue, setDisplayedValue] = useState(stat.value);
  const metricStringSuccess = `${match.id}_${stat.name}_success`;
  const metricStringFail = `${match.id}_${stat.name}_fail`;

  useEffect(() => {
    if (
      selectedMetricFilters.includes(metricStringSuccess) &&
      selectedMetricFilters.includes(metricStringFail)
    ) {
      setDisplayedValue(stat.value);
    } else if (selectedMetricFilters.includes(metricStringSuccess)) {
      if (stat.details) {
        if (stat.details?.success?.value === null) {
          setDisplayedValue(0);
        } else if (stat.details?.success?.value === undefined) {
          setDisplayedValue(stat.value);
        } else {
          setDisplayedValue(stat.details?.success?.value);
        }
      } else {
        if (stat.value !== null) {
          setDisplayedValue(stat.value);
        } else {
          setDisplayedValue(0);
        }
      }
    } else if (selectedMetricFilters.includes(metricStringFail)) {
      setDisplayedValue(stat.details?.fail?.value || 0);
    } else {
      setDisplayedValue(stat.value);
    }
  }, [selectedMetricFilters]);
  return (
    <div
      className={`${displayedValue === null ? 'na-stat' : displayedValue > 0 ? 'stat-value-container enabledClickable' : 'stat-value-container-zero'}`}
      style={{ minWidth: 70 }}
      onClick={(e) => {
        e.stopPropagation();
        if (displayedValue) {
          dispatch(
            setExpandedGameClickedId({
              id: match.id,
              expanded: expanded.includes(match.id),
            }),
          );
          if (
            selectedMetricFilters.includes(metricStringSuccess) &&
            !selectedMetricFilters.includes(metricStringFail) &&
            stat.details
          ) {
            window.open(
              makeLink(match, openedTeamPlayer.team, {
                key: stat.details?.success.key,
              }),
              '_blank',
            );
          } else if (
            selectedMetricFilters.includes(metricStringFail) &&
            !selectedMetricFilters.includes(metricStringSuccess) &&
            stat.details
          ) {
            window.open(
              makeLink(match, openedTeamPlayer.team, {
                key: stat.details?.fail.key,
              }),
              '_blank',
            );
          } else {
            window.open(makeLink(match, openedTeamPlayer.team, stat), '_blank');
          }
        }
      }}
    >
      {displayedValue ? <PlayCircle height={32} /> : <></>}
      <div
        style={{ flex: 1, height: 32 }}
        className={`${displayedValue === null ? 'flex-row j-ce ai-c disabled-stat-color' : 'flex-row ai-c'}`}
      >
        {displayedValue === null ? t('n/a') : displayedValue}
      </div>
      <div className="stat-average">
        {(selectedMetricFilters.includes(metricStringSuccess) &&
          selectedMetricFilters.includes(metricStringFail)) ||
        (!selectedMetricFilters.includes(metricStringSuccess) &&
          !selectedMetricFilters.includes(metricStringFail) &&
          stat.average !== undefined &&
          displayedValue)
          ? `${stat.average}%`
          : ''}
      </div>
    </div>
  );
};
export default StatValueTag;
