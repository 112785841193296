import './index.css';
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { GetCurrentUserAction } from 'actions/auth.actions';
import { GetTeamsAction } from 'actions/clubs.actions';
import { setStyleModeAction } from 'actions/interface.actions';
import {
  AC_SetActiveVideoAction,
  AC_SetPlayerLoadingAction,
  AC_setPlayerMode,
} from 'actions/player.acitons';
import ContentContainer from 'components/ContentContainer';
import VideoListController, { MODES } from 'components/VideoListController';
import { VideoPlayerController } from 'components/VideoPlayerController';
import { AppStateType } from 'reducers';
import { reelsReducer } from 'reducers/reels.reducer';
import { videosListReducer } from 'reducers/videosList.reducer';
import { useAppDispatch } from 'store';
import { STYLE_TYPES } from 'types/constants';

const PlaylistsPage = () => {
  const dispatch = useAppDispatch();
  const { currentUser } = useSelector(
    (state: AppStateType) => state.authReducer,
  );
  const { resetReelsState } = reelsReducer.actions;
  const { AC_initialzeSelectedEpisodes } = videosListReducer.actions;
  const [t] = useTranslation();
  useEffect(() => {
    dispatch(GetCurrentUserAction());
    dispatch(setStyleModeAction(STYLE_TYPES.dark));
    dispatch(AC_setPlayerMode(MODES.playlists));
    dispatch(AC_SetActiveVideoAction(null));
    dispatch(resetReelsState());
    dispatch(AC_SetPlayerLoadingAction(false));
    dispatch(AC_initialzeSelectedEpisodes({}));
    window.document.body.setAttribute('data-theme', 'dark');
    const metaElement = document.getElementById('meta-top-name');
    const titleElement = document.getElementById('title-top-name');
    if (metaElement && titleElement) {
      metaElement.setAttribute('content', t('Playlists'));
      titleElement.innerHTML = t('Playlists');
    }
  }, []);
  useEffect(() => {
    if (currentUser) {
      dispatch(GetTeamsAction(currentUser.clubs[0]));
    }
  }, [currentUser]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showNavigationPanel, setShowNavigationPanel] = useState(true);
  const [wideScreenMode, setWideScreenMode] = useState(false);

  return (
    <ContentContainer
      pageTitle={''}
      page={'playlists'}
      theme="dark"
      boxSizing={'border-box'}
      showNavigationPanel={!wideScreenMode}
      wideScreenMode={wideScreenMode}
      rightPanel={
        showNavigationPanel && (
          <VideoListController
            isWideScreenMode={wideScreenMode}
            toggleWideScreen={setWideScreenMode}
          />
        )
      }
    >
      <VideoPlayerController
        isNarrowScreenMode={wideScreenMode}
        toggleFullScreen={setWideScreenMode}
      />
    </ContentContainer>
  );
};
export default PlaylistsPage;
