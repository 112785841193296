import { MenuProps } from 'antd';

export enum EventType {
  training = 'training',
  game = 'game',
}
export enum VideoCreationType {
  camera = 'camera',
  userUpload = 'userUpload',
}
export enum CalendarSideModeMode {
  edit = 'edit',
  create = 'create',
  record = 'record',
}
export enum EpisodeFilterPanelTypes {
  players = 'players',
  matches = 'matches',
  metrics = 'metrics',
  timing = 'timing',
}
export enum EpisodeFilterTypes {
  players = 'players',
  games = 'games',
  training = 'training',
  attributes = 'attributes',
  match_part = 'match_part',
  training_part = 'training_part',
  show_my_episodes = 'show_my_episodes',
}
export type VideoFileType = {
  id: string;
  name: string;
  videoId?: string;
  file_path?: string;
  file_url?: string;
  camera?: string | null;
  created_at?: string;
  uploaded?: boolean;
  uploaded_by?: number | null;
  percent?: number;
  status?: string;
  isSent?: boolean;
  duration?: number;
  meta?: any;
  error?: string;
  actionId?: string;
  type?: string;
};
export type CalendarEventTypeAPI = {
  id?: string;
  type: EventType;
  teams: Array<number>;
  game_type?: string;
  name: string;
  description?: string;
  started_at?: string;
  finished_at?: string | null;
  camera?: string;
  event_videos?: Array<VideoFileType | undefined>;
  activity_videos?: Array<VideoFileType | undefined>;
  hasStats?: boolean;
  hasVideo?: boolean;
  hasMarkup?: boolean;
  time_zone: string;
  city?: string;
  city_id?: string;
  tournament?: number | null;
};
export type CalendarEventUpdateTypeAPI = {
  id: string;
  type?: EventType;
  teams?: Array<number>;
  game_type?: string;
  name?: string;
  description?: string;
  started_at?: string;
  finished_at?: string | null;
  camera?: string;
  event_videos?: Array<VideoFileType | undefined>;
  activity_videos?: Array<VideoFileType | undefined>;
  hasStats?: boolean;
  hasVideo?: boolean;
  hasMarkup?: boolean;
  time_zone?: string;
  city?: string;
  city_id?: string;
  tournament?: number | null;
};
export type CalendarDayEventType = {
  id?: string;
  type: EventType;
  teams: Array<number>;
  game_type?: string;
  name: string;
  description?: string;
  city?: string;
  city_id?: string;
  date: {
    day: number;
    month: number;
    year: number;
    startHour: number;
    startMinute: number;
    finishHour?: number;
    finishMinute?: number;
    started_at?: string;
    finished_at?: string;
    time_zone: CityType | null | undefined | string;
  };
  camera?: CameraType;
  activity_videos?: Array<VideoFileType>;
  hasStats?: boolean;
  hasVideo?: boolean;
  hasMarkup?: boolean;
  hasProtocol?: boolean;
  hasUknownPlayers?: boolean;
  tournament?: number;
};

export type DateType = {
  date: number;
  month: number;
  year: number;
};
export type CameraType = {
  id: string;
  name: string;
  status: {
    isRecording: boolean;
    recordingTime: string | null;
    readyToRecord: boolean;
    spaceLeftHours: number;
    totalSpaceHours: number;
    recordedEventId: string | null;
  };
};
export type UserType = {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  groups: Array<any>;
  clubs: Array<number>;
  teams: Array<number>;
  current_team: number;
  playlist_sorting: string;
  custom_permissions: string[];
};
export type UserUpdateType = {
  id: number;
  email?: string;
  first_name?: string;
  last_name?: string;
  groups?: Array<any>;
  clubs?: Array<number>;
  teams?: Array<number>;
  playlist_sorting?: string;
};
export type NewTeamType = {
  name: string;
  image: string | null;
  club: number;
};
export interface TeamType extends NewTeamType {
  id: number;
  created_at?: string;
  updated_at?: string;
}
export type ClubType = {
  id: number;
  teams: Array<TeamType>;
  created_at: string;
  updated_at: string;
  name: string;
  image: string | null;
  country: number;
};
export type CityType = {
  id: number;
  name: string;
  country_code: string;
  current_zone_time: string;
  gmt_offset: number;
  city?: string;
  city_id?: string;
};
export type GameVideoEpisodeType = {
  id: string;
  name: string;
  started_at: number;
  finished_at: number;
  game_timer: any;
  file_timer: any;
  user_timer: any;
  players?: Array<string>;
  event_type?: EpisodeAttributeType;
  filtered_attributes?: Array<EpisodeAttributeType>;
  video: any | null;
  episode_type?: string;
  period_name?: string;
  period_offset?: number;
  user_event_name?: string;
  merged_name?: string;
  initial_game_timer?: any;
  keyframes?: KeyFrameType[];
  attributes?: any;
};
export type PlaylistElementType = {
  id: string;
  event_id: string;
  // name: string
  game_timer: any;
  file_timer: any;
  user_timer: any;
  players_names?: Array<string>;
  attributes?: Array<EpisodeAttributeType>;
};
export type GameVideoType = {
  id: string;
  game_type: string;
  started_at?: any;
  name: string;
  type?: string;
  user_events?: string[];
  user_episodes?: [];
  tournament?: any;
  home_team: any;
  away_team: any;
  result: any;
  sections: Array<GameVideoEpisodeType>;
  game_events: Array<GameVideoEpisodeType>;
  episodes: Array<GameVideoEpisodeType>;
  events?: Array<string>;
  isExpanded?: boolean | undefined;
  activity_videos?: any;
  periods?: any[];
};
export type PlaylistType = {
  name: string;
  id: string;
  periods?: any[];
  share_link?: string;
  episodes?: Array<GameVideoEpisodeType>;
  isExpanded?: boolean | undefined;
  user_episodes?: string[];
};
export type PlayerType = {
  id: string;
  first_name: string | null;
  last_name: string | null;
  created_at: string;
  updated_at: string;
  birthday: string | null;
  weight: number | null;
  height: number | null;
  team: number | null;
  photo: string | null;
  type: { value: string; label: string };
};
export type NewPlayerType = {
  first_name: string | null;
  last_name: string | null;
  birthday?: string | null;
  weight?: number | null;
  height?: number | null;
  team: number | null;
  photo?: string | null;
};
export type EpisodeAttributeType = {
  id: string;
  name: string;
  category?: string;
  activity_type?: string;
};
export type DateLimitType = {
  from: string;
  to: string;
};
export type SeasonType = {
  created_at: string;
  id: number;
  start_date: string;
  end_date: string;
  teams: number[];
};
export type TournamentSimpleType = {
  id: number;
  name: string;
  is_international: boolean;
  country: number;
};
export type TournamentType = {
  id: number;
  name: string;
  is_international: boolean;
  country: number;
  seasons: SeasonType[];
  image?: string;
};
export type SelectedEpisodesType = {
  [key: string]: string[];
};
export type EventsFilterType = {
  [key: number]: string[];
};
export type LastXFilterType = {
  [key: string]: string;
};
export enum PlayCommandType {
  play = 'play',
  resume = 'resume',
  pause = 'pause',
  stop = 'stop',
  none = 'none',
  scrollTo = 'scrollTo',
}
export enum EpisodeTypes {
  match = 'match',
  match_part = 'match_part',
  period = 'period',
  user_episode = 'user_episode',
  episode = 'episode',
}
export enum FieldValidationStatus {
  success = 'success',
  error = 'error',
  warning = 'warning',
  validating = 'validating',
}
interface CustomMenuProps extends MenuProps {
  data: string;
}
export type MenuItemType = Required<CustomMenuProps>['items'][number];
export type SubMenuItemType = MenuItemType & {
  children?: MenuItemType[];
  key: React.Key;
};
export type GrouppedPlayersType = {
  [key: string]: Array<any>;
};
export type KeyFrameType = {
  time: number;
  x: number;
  y: number;
  xRatio: number;
  yRatio: number;
  width: number;
  height: number;
  heightRatio: number;
  widthRatio: number;
};
export type ReelFramePositionType = {
  x: number;
  y: number;
  xRatio: number;
  yRatio: number;
  width: number;
  height: number;
  heightRatio: number;
  widthRatio: number;
};
export type Download = {
  id: string;
  name: string;
  duration: number;
  status: string;
  created_at: string;
  progress: number | string;
  resulting_archives: string[];
  qty: number;
};
export type NotificationType = {
  id: string;
  title: string;
  body: string;
  url?: string;
  is_read?: boolean;
};

export type personaPercentilesType = {
  key: string;
  metric_id: number;
  name: string;
  pth: number;
};
