import React, { useEffect, useState } from 'react';

import {
  Tooltip as ATooltip,
  Button,
  Col,
  ConfigProvider,
  Divider,
  Empty,
  Flex,
  Radio,
  Row,
  Skeleton,
  Table,
  Tree,
  TreeDataNode,
  TreeProps,
} from 'antd';
import Column from 'antd/es/table/Column';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import './index.css';

import {
  // ClearSelectedGames,
  FilterApply,
  SetSelectedLastMatch,
} from 'actions/persona.actions';
import { AppStateType } from 'reducers';
// import { personaReducer } from 'reducers/persona.reducer';
import { teamPlayerReducer } from 'reducers/teamPlayer.reducer';
import { teamPlayerStatsReducer } from 'reducers/teamPlayerStats.reducer';
import { useAppDispatch } from 'store';
import { ActivityType } from 'types/playlist.types';

import { ReactComponent as DownOutlinedBlack } from '../../../../assets/img/icons/faDownOutlinedBlack.svg';

const prepareTreeStructure = (
  data: any,
  expandedKeys: string[],
): TreeDataNode[] => {
  return data.map((season: any) => {
    return {
      title: season.name,
      key: `0-${season.name}`,
      switcherIcon: (
        <DownOutlinedBlack
          className={`${expandedKeys.includes(`0-${season.name}`) ? 'rotate180' : 'rotate90'}`}
        />
      ),
      children: season.leagues.map((l: any) => ({
        title: l.name,
        key: `1-${season.name}-${l.tournament_id}`,
        data: l.matches,
      })),
    };
  });
};
interface GameDataType {
  key: React.Key;
  gameDetails: ActivityType;
}
export const PlayerStatisticsContent = ({
  hide,
  // firstSeason,
  // checkedSeasons,
  // setCheckedSeasons,
}: {
  hide: () => void;
  firstSeason: string;
  isOpen: boolean;
  // checkedSeasons: any[];
  // setCheckedSeasons: any;
}) => {
  const dispatch = useAppDispatch();
  const [t] = useTranslation();
  const {
    teamPlayerStatsMatches,
    teamPlayerStatsState,
    selectedLastMatch,
    selectedMatches,
    selectedRowKeys,
    checkedSeasons,
    // appliedMatches,
    // appliedSeasons,
    // appliedTournaments,
    tableMatches,
    expandedSeasons,
    // initializeGamesCommand,
  } = useSelector((state: AppStateType) => state.teamPlayerStatsReducer);
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setTableMatches,
    setAppliedSeasons,
    setSelectedRowKeys,
    setSelectedTournaments,
    setAppliedTournaments,
    setAppliedLastMatches,
    setAppliedMatches,
    setSelectedMatches,
    setCheckedSeasons,
    setExpandedSeasons,
  } = teamPlayerStatsReducer.actions;
  const { openedTeamPlayer } = useSelector(
    (state: AppStateType) => state.teamPlayerReducer,
  );
  const [preparedTreeStructure, setPreparedTreeStructure] = useState<
    TreeDataNode[]
  >([]);
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  useEffect(() => {
    setExpandedKeys(expandedSeasons);
  }, [expandedSeasons]);
  // const initializeGames = () => {
  //   if ((appliedSeasons[0] || firstSeason) && !selectedLastMatch) {
  //     const checkedInitialSeasons = [
  //       `1-${appliedSeasons[0] || firstSeason}-${
  //         appliedTournaments[0] ||
  //         teamPlayerStatsMatches[0]?.leagues[0].tournament_id
  //       }`,
  //     ];
  //     setExpandedKeys([`0-${appliedSeasons[0] || firstSeason}`]);
  //     helper(checkedInitialSeasons, false);
  //   } else if (selectedLastMatch) {
  //     populateLastMatches();
  //   }
  // };
  useEffect(() => {
    if (openedTeamPlayer) {
      setPreparedTreeStructure(
        prepareTreeStructure(teamPlayerStatsMatches, expandedKeys),
      );
    }
  }, [openedTeamPlayer, teamPlayerStatsMatches, expandedKeys]);
  const { setSelectedMatchesStats } = teamPlayerReducer.actions;
  const populateLastMatches = () => {
    if (selectedLastMatch) {
      let matches: any[] = [];
      preparedTreeStructure.forEach((season: TreeDataNode) => {
        season.children?.forEach((child: any) => {
          child.data.forEach((match: any) => {
            matches.push({
              key: match.id,
              gameDetails: match,
            });
          });
        });
      });
      matches = matches.sort((a: any, b: any) =>
        dayjs(b.gameDetails.date).diff(dayjs(a.gameDetails.date)),
      );
      if (selectedLastMatch === '5') {
        matches = matches.slice(0, 5);
      } else if (selectedLastMatch === '10') {
        matches = matches.slice(0, 10);
      }
      dispatch(setTableMatches(matches));
      dispatch(setSelectedRowKeys(matches.map((match: any) => match.key)));
      dispatch(
        FilterApply(
          checkedSeasons,
          matches.map((match: any) => match.key),
        ),
      );
    }
  };
  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    _: any,
    info: any,
    filter = true,
  ) => {
    dispatch(setSelectedRowKeys(newSelectedRowKeys));
    dispatch(SetSelectedLastMatch(''));
    filter && dispatch(FilterApply(checkedSeasons, newSelectedRowKeys));
  };

  const helper = (checkedKeys: any, filter: boolean) => {
    let flMatches: any = [];
    checkedKeys
      .filter((key: any) => key.length > 3 && key.indexOf('-') !== -1)
      .forEach((key: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const split = key.split('-');
        let index;
        let seasonId;
        let LeagueId: any;
        if (split.length === 2) {
          [index, seasonId] = split;
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          [index, seasonId, LeagueId] = split;
        }
        const matches = preparedTreeStructure
          .filter((season: TreeDataNode) => {
            return season.key === `0-${seasonId}`;
          })[0]
          ?.children?.filter((child: any) => {
            return child.key === `1-${seasonId}-${LeagueId}`;
          })[0];
        if (matches) {
          flMatches = flMatches
            //@ts-ignore
            .concat(matches.data || [])
            .sort((a: any, b: any) => dayjs(b.date).diff(dayjs(a.date)));
        }
      });
    const tableMatches = flMatches.map((match: any) => {
      return {
        key: match.id,
        gameDetails: match,
      };
    });
    dispatch(setTableMatches(tableMatches));
    onSelectChange(
      flMatches.map((match: any) => match.id),
      {},
      {},
      filter,
    );
    dispatch(setSelectedMatches(flMatches));
    dispatch(
      setSelectedMatchesStats(tableMatches.map((match: any) => match.key)),
    );
  };
  const onCheck: TreeProps['onCheck'] = (checkedKeys) => {
    dispatch(SetSelectedLastMatch(null));
    if (Array.isArray(checkedKeys)) {
      helper(checkedKeys, true);
      dispatch(
        setSelectedTournaments(
          checkedKeys.filter((el: any) => el.split('-').length > 2),
        ),
      );
      dispatch(setCheckedSeasons(checkedKeys));
    } else {
      console.log('checkedKeys is not an array');
    }
  };
  const onLastMatchRadioChange = async (e: any) => {
    await dispatch(setCheckedSeasons([]));
    await dispatch(setSelectedTournaments([]));
    await dispatch(SetSelectedLastMatch(e.target.value));
  };
  useEffect(() => {
    populateLastMatches();
  }, [selectedLastMatch]);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const onExpand = (data: any) => {
    dispatch(setExpandedSeasons(data));
  };
  return (
    <div className="personSettingPopover">
      <div className="personSettingHeader">
        <Row>
          <Col span={12}>
            <div>{t('Select matches')}</div>
          </Col>
          <Col span={12}>
            <Flex justify="end" gap={8}>
              {/* <Button
                type="default"
                onClick={() => {
                  hide();
                  setCheckedSeasons(appliedSeasons);
                  dispatch(SetSelectedLastMatch(null));
                  setSelectedRowKeys(appliedMatches);
                }}
              >
                {t('Cancel')}
              </Button> */}
              <Button
                type="primary"
                disabled={
                  selectedRowKeys !== undefined
                    ? selectedRowKeys.length === 0
                    : true
                }
                onClick={() => {
                  dispatch(setAppliedLastMatches(selectedLastMatch));
                  dispatch(SetSelectedLastMatch(null));
                  dispatch(setAppliedMatches(selectedRowKeys));
                  dispatch(
                    setAppliedSeasons(
                      checkedSeasons
                        .filter((id: string) => {
                          return id.split('-').length === 2;
                        })
                        .map((id: string) => {
                          return id.split('-')[1];
                        }),
                    ),
                  );
                  dispatch(
                    setAppliedTournaments(
                      checkedSeasons.map((id: string) => {
                        if (id.split('-').length > 2) {
                          return id.split('-')[2];
                        }
                      }),
                    ),
                  );
                  hide();
                }}
              >
                {t('Apply a')}
              </Button>
            </Flex>
          </Col>
        </Row>
      </div>
      <Divider />
      <Row gutter={24} className="personFilterContainer">
        <Col span={8} className="personFilterVerticalDivider">
          <Radio.Group
            onChange={onLastMatchRadioChange}
            value={selectedLastMatch}
          >
            <Radio.Button className="personMatchCountSelect" value="5">
              {t('Last 5 matches')}
            </Radio.Button>
            <Radio.Button className="personMatchCountSelect" value="10">
              {t('Last 10 matches')}
            </Radio.Button>
            <Radio.Button className="personMatchCountSelect" value="all">
              {t('All matches')}
            </Radio.Button>
          </Radio.Group>
          <Divider />
          <Tree
            className="treeSelect"
            checkable
            selectable={false}
            blockNode
            checkedKeys={checkedSeasons}
            expandedKeys={expandedKeys}
            onExpand={onExpand}
            onCheck={onCheck}
            treeData={preparedTreeStructure}
          />
        </Col>
        <Col span={16} className="personFilterMatches">
          <Skeleton
            active
            loading={teamPlayerStatsState === 'loading'}
            paragraph={{ rows: 1, width: ['100%'] }}
          />
          <Skeleton
            active
            loading={teamPlayerStatsState === 'loading'}
            paragraph={{ rows: 1, width: ['100%'] }}
          />
          <Skeleton
            active
            loading={teamPlayerStatsState === 'loading'}
            paragraph={{ rows: 1, width: ['100%'] }}
          />

          {teamPlayerStatsState === 'loaded' && (
            <>
              <ConfigProvider
                theme={{
                  components: {
                    Table: {
                      headerBg: 'var(--colorBgContainer)',
                      borderColor: 'none',
                      cellPaddingBlock: 8,
                      cellPaddingInline: 6,
                      rowSelectedBg: 'none',
                    },
                  },
                }}
              >
                <Table<GameDataType>
                  rowSelection={rowSelection}
                  dataSource={tableMatches}
                  pagination={false}
                  bordered={false}
                  rowHoverable={false}
                  rowClassName={'enabledClickable'}
                  locale={{
                    emptyText: (
                      <Empty
                        description={t(
                          'Please, select a season and a tournament',
                        )}
                      />
                    ),
                  }}
                  onRow={(record) => ({
                    onClick: () => {
                      const selectedKey: any = record.key;
                      let x: any;
                      if (selectedRowKeys === undefined) {
                        onSelectChange([selectedKey], {}, {}, true);
                        x = [selectedKey];
                      } else if (selectedRowKeys.includes(selectedKey)) {
                        // If selected, deselect it
                        x = selectedRowKeys.filter(
                          (key: any) => key !== selectedKey,
                        );
                        onSelectChange(x, {}, {}, true);
                      } else {
                        // Otherwise, add it to the selection
                        x = [...selectedRowKeys, selectedKey];
                        onSelectChange(x, {}, {}, true);
                      }
                      dispatch(setSelectedRowKeys(x));
                    },
                  })}
                >
                  <Column
                    render={(match: any) => {
                      return (
                        <div className="oy-h">
                          <div
                            className={`games_filter_game_block_name ${selectedMatches.includes(match) ? 'color-text-heading' : 'color-text-label'}`}
                          >
                            {match.gameDetails.home_team}{' '}
                            {match.gameDetails.score?.score.replace('-', ':')}{' '}
                            {match.gameDetails.away_team}
                          </div>
                          <div
                            className={`games_filter_game_block_info ${selectedMatches.includes(match) ? 'color-text-label' : 'color-description'}`}
                          >
                            <div>{match.gameDetails.date}</div>
                            {match.gameDetails.country && (
                              <>
                                <div>
                                  <Divider
                                    type="vertical"
                                    style={{ margin: '0 0 0 0' }}
                                  />
                                </div>
                                <ATooltip
                                  title={match.gameDetails.country}
                                  arrow={true}
                                >
                                  <div className="elipsis-text">
                                    {match.gameDetails.country}
                                  </div>
                                </ATooltip>
                                <div>
                                  <Divider
                                    type="vertical"
                                    style={{ margin: '0 0 0 0' }}
                                  />
                                </div>
                                <ATooltip
                                  title={match.gameDetails.country}
                                  arrow={true}
                                >
                                  <div className="elipsis-text">
                                    {match.gameDetails.league}
                                  </div>
                                </ATooltip>
                              </>
                            )}
                            {match.gameDetails.tournament && (
                              <>
                                <div>
                                  <div className="divider"></div>
                                </div>
                                <ATooltip
                                  title={match.gameDetails.tournament}
                                  arrow={true}
                                >
                                  <div>{match.gameDetails.tournament}</div>
                                </ATooltip>
                              </>
                            )}
                          </div>
                        </div>
                      );
                    }}
                  />
                </Table>
              </ConfigProvider>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};
