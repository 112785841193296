import React, { FC, useState } from 'react';

import { ConfigProvider, Dropdown, MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ElipsisVertical } from '../../../assets/img/icons/faElipsisVThin.svg';
interface Interface {
  renameCallback: any;
  shareCallback: any;
  deleteCallback: any;
  reorderByTimeCallback: any;
  moveToFolderCallback: any;
  playlist: any;
  deleteSelectedFromPlaylist: any;
  hasSelected: boolean;
}
const PlaylistViewContext: FC<Interface> = (props: any) => {
  const [t] = useTranslation();
  const [ddIsOpen, setDDIsOpen] = useState(false);
  const items: MenuProps['items'] = [
    {
      key: 2,
      label: (
        <div onClick={() => props.renameCallback(props.playlist.id)}>
          {t('Rename')}
        </div>
      ),
    },
    {
      key: 3,
      label: (
        <div
          onClick={() => props.reorderByTimeCallback(props.playlist.id)}
          style={{
            textWrap: 'wrap',
          }}
        >
          {t('Reorder by time')}
        </div>
      ),
      disabled: true,
    },
    {
      key: 4,
      label: (
        <div
          onClick={() =>
            props.moveToFolderCallback(props.playlist.id || props.playlist.key)
          }
        >
          {t('Move to folder')}
        </div>
      ),
      disabled: false,
    },
    {
      key: 5,
      label: (
        <div onClick={() => props.deleteCallback(props.playlist.id)}>
          {t('Delete')}
        </div>
      ),
      disabled: false,
    },
    {
      key: 5,
      label: (
        <div
          onClick={() => props.deleteSelectedFromPlaylist()}
          style={{
            textWrap: 'wrap',
          }}
        >
          {t('Delete selected from playlist')}
        </div>
      ),
      disabled: !props.hasSelected,
    },
  ];
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgElevated: 'var(--colorBgSpotlight)',
          colorText: 'var(--colorText)',
        },
      }}
    >
      <Dropdown
        className="enabledClickable"
        menu={{ items }}
        trigger={['click']}
        overlayStyle={{
          width: 'max-content',
          maxWidth: '183px',
          textWrap: 'wrap',
        }}
        onOpenChange={() => setDDIsOpen((prev) => !prev)}
        placement="bottomRight"
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: 32,
            justifyContent: 'center',
            height: 32,
          }}
          className={ddIsOpen ? 'activeButton' : ''}
        >
          <ElipsisVertical width={20} height={16} />
        </div>
      </Dropdown>
    </ConfigProvider>
  );
};
export default PlaylistViewContext;
