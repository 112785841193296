import React, { useEffect, useRef, useState } from 'react';

import './index.css';
import { Button, ConfigProvider, Input } from 'antd';
import { InputStatus } from 'antd/es/_util/statusUtils';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  AC_ReplaceUserEventsToExpandedVideo,
  AC_SetAllowPlayFirstAction,
  AC_SetPlayerHotkeysAllowedAction,
  AC_SetVideoTimeZoomAction,
} from 'actions/player.acitons';
import { EventsAPI } from 'api/events';
import { PlaylistsAPI } from 'api/playlists';
import { MODES } from 'components/VideoListController';
import { AppStateType } from 'reducers';
import { notificationsReducer } from 'reducers/notifications.reducer';
import { playerReducerV2 } from 'reducers/player.reducer';
import { playlistReducerV2 } from 'reducers/playlist.reducer';
import { useAppDispatch } from 'store';
import { EDIT_VIDEO_WINDOW_WIDTH_MS } from 'types/constants';
import { generateUUID } from 'types/crypto';
import {
  convertTimeToMilliseconds,
  filterAndIndex,
  formatMillisecondsToTime,
  handleVideoTimeInputChange,
  validateValue,
} from 'types/functions';
import { EpisodeTypes } from 'types/types';

const CreateEpisodePlayerController = ({
  // currentPosition,
  // duration,
  closeCallback,
  to,
  setTo,
  from,
  setFrom,
  // editedTo,
  // editedFrom,
  // setEditedTo,
  // setEditedFrom,
}: {
  currentPosition: number | undefined;
  duration: number | undefined;
  closeCallback: any;
  to: any;
  setTo: any;
  from: any;
  setFrom: any;
  // editedTo: any;
  // editedFrom: any;
  setEditedTo: any;
  setEditedFrom: any;
}) => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const { activeVideo, playerMode, filteredVideos } = useSelector(
    (state: AppStateType) => state.playerReducer,
  );
  const {
    playedEpisode,
    // videosListEditMode,
    playedVideoSet,
    editedEpisodeRange,
    editedFileEpisodeRange,
  } = useSelector((state: AppStateType) => state.playerReducerV2);
  const { openedPlaylist } = useSelector(
    (state: AppStateType) => state.playlistReducerV2,
  );
  const { addUserEpisodeToOpenedPlaylist } = playlistReducerV2.actions;
  const initFromTime = (from: number) => {
    return formatMillisecondsToTime(from < 10000 ? 0 : from - 10000);
  };
  const [inputValueFrom, setInputValueFrom] = useState(
    initFromTime(editedEpisodeRange[0]),
  );
  const [inputValueTo, setInputValueTo] = useState<string>(
    initFromTime(editedEpisodeRange[1]),
  );
  useEffect(() => {
    if (activeVideo && activeVideo.duration !== undefined) {
      if (editedEpisodeRange[1] + 0 * 1000 < activeVideo?.duration * 1000) {
        setInputValueTo(formatMillisecondsToTime(editedEpisodeRange[1]));
      } else {
        activeVideo?.duration !== undefined &&
          setInputValueTo(
            formatMillisecondsToTime(
              activeVideo.duration !== undefined
                ? activeVideo.duration * 1000
                : 0,
            ),
          );
      }
    }
    if (editedEpisodeRange[0] < 5000) {
      setInputValueFrom(formatMillisecondsToTime(0));
    } else {
      setInputValueFrom(
        formatMillisecondsToTime(editedEpisodeRange[0] - 0 * 1000),
      );
    }
    refFrom.current = editedEpisodeRange[0];
    refTo.current = editedEpisodeRange[1];
  }, [editedEpisodeRange, activeVideo]);
  const [episodeName, setEpisodeName] = useState<string>(t('New episode'));
  const refTo = useRef<number>(0);
  const refFrom = useRef<number>(0);
  const {
    setEditedEpisodeFrom,
    setEditedEpisodeTo,
    setEditedFileEpisodeFrom,
    setEditedFileEpisodeTo,
    // resetEditMode,
  } = playerReducerV2.actions;
  const handleBlur = (value: string, callback: any, type: string) => {
    dispatch(AC_SetPlayerHotkeysAllowedAction(true));
    const validatedValue = validateValue(value);
    const time = convertTimeToMilliseconds(validatedValue);
    let offset;
    if (
      playedEpisode?.user_timer !== undefined &&
      playedEpisode?.user_timer !== null
    ) {
      offset =
        playedEpisode?.user_timer?.started_at -
        playedEpisode?.game_timer?.started_at;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      offset =
        playedEpisode?.file_timer.started_at -
        playedEpisode?.game_timer.started_at;
    }
    if (type === 'to') {
      if (isNaN(time)) {
        setInputValueTo(formatMillisecondsToTime(refTo.current));
        return false;
      }
      const msDelta = editedEpisodeRange[1] % 1000;
      if (time === 0 && time >= from) {
        if (callback) {
          dispatch(setEditedEpisodeTo(0));
          dispatch(setEditedFileEpisodeTo(offset));
          callback(0);
        }
        return true;
      }
      if (activeVideo?.duration && time > activeVideo?.duration * 1000) {
        // setInputValueTo(formatMillisecondsToTime(refTo.current));
        return false;
      }
      if (time < editedEpisodeRange[0]) {
        return false;
      }
      if (activeVideo?.duration && time > 0) {
        if (time <= activeVideo?.duration * 1000) {
          if (
            time < editedEpisodeRange[1] &&
            time - editedEpisodeRange[1] > EDIT_VIDEO_WINDOW_WIDTH_MS
          ) {
            return false;
          }
          if (time < editedEpisodeRange[0]) {
            return false;
          }
          if (time !== undefined) {
            // setEditedTo(refFrom.current);
            if (callback) {
              dispatch(setEditedEpisodeTo(time + msDelta));
              dispatch(setEditedFileEpisodeTo(time + offset + msDelta));
              callback(time + msDelta);
            }
            // setInputValueTo(formatMillisecondsToTime(time));
            return true;
          }
          return false;
        } else {
          if (callback) {
            callback(activeVideo?.duration * 1000);
          }
          return true;
        }
      }
    } else {
      if (isNaN(time)) {
        // setInputValueFrom(formatMillisecondsToTime(refFrom.current));
        return false;
      }
      const msDelta = editedEpisodeRange[0] % 1000;
      if (time !== null) {
        if (
          time < editedEpisodeRange[0] &&
          editedEpisodeRange[0] - time > EDIT_VIDEO_WINDOW_WIDTH_MS
        ) {
          return false;
        }
        if (time <= editedEpisodeRange[1]) {
          if (callback) {
            callback(time + msDelta);
            dispatch(setEditedEpisodeFrom(time + msDelta));
            dispatch(setEditedFileEpisodeFrom(time + offset + msDelta));
          }
          return true;
        }
        // setInputValueFrom(formatMillisecondsToTime(refFrom.current));
        return false;
      }
      if (callback) {
        callback(0);
        dispatch(setEditedEpisodeFrom(0));
        dispatch(setEditedFileEpisodeFrom(offset));
      }
      return true;
    }
  };
  const { showNotification } = notificationsReducer.actions;
  const addUserEpisode = async () => {
    const fromIsValid = handleBlur(inputValueFrom, undefined, 'from');
    const toIsValid = handleBlur(inputValueTo, undefined, 'to');
    setFromInputStatus(fromIsValid ? '' : 'error');
    setToInputStatus(toIsValid ? '' : 'error');
    let offset;
    if (
      playedEpisode?.user_timer !== undefined &&
      playedEpisode?.user_timer !== null
    ) {
      offset =
        playedEpisode?.user_timer.started_at -
        playedEpisode?.game_timer.started_at;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      offset =
        playedEpisode?.file_timer.started_at -
        playedEpisode?.game_timer.started_at;
    }
    if (
      toIsValid &&
      fromIsValid &&
      inputValueFrom &&
      inputValueTo &&
      playedVideoSet
    ) {
      const period = playedVideoSet.periods?.filter((p: any) => {
        if (p.started_at <= from && p.finished_at >= to) {
          return p;
        }
      })[0];
      const episode: any = {
        video: {
          id: activeVideo?.videoId,
          file_path: activeVideo?.file_path,
          file_url: activeVideo?.file_url,
          meta: {
            video_length: activeVideo?.duration,
          },
        },
        game_timer: {
          started_at: editedEpisodeRange[0],
          finished_at: editedEpisodeRange[1],
        },
        user_timer: {
          started_at: editedEpisodeRange[0] + offset,
          finished_at: editedEpisodeRange[1] + offset,
        },
        file_timer: {
          started_at: editedFileEpisodeRange[0],
          finished_at: editedFileEpisodeRange[1],
        },
        period_name: period?.label,
        event_name: episodeName,
        user_event_name: episodeName,
        isTemporary: true,
        episode_type: EpisodeTypes.user_episode,
      };
      if (playerMode === MODES.playlist_view && openedPlaylist) {
        const playlistEpisode = {
          ...episode,
        };
        playlistEpisode.game_timer.started_at += offset;
        playlistEpisode.game_timer.finished_at += offset;
        playlistEpisode.user_timer.started_at += offset;
        playlistEpisode.user_timer.finished_at += offset;
        const response = await EventsAPI.addNewUserEpisode(playlistEpisode);
        const createdEpisode = response.data;
        episode.id = createdEpisode.id;
        episode.period_name = createdEpisode.period_name;
        episode.game_timer.started_at =
          createdEpisode.game_timer.started_at - offset;
        episode.game_timer.finished_at =
          createdEpisode.game_timer.finished_at - offset;
        episode.user_timer.started_at = createdEpisode.user_timer.started_at;
        episode.user_timer.finished_at = createdEpisode.user_timer.finished_at;
        PlaylistsAPI.addToPlaylists(
          [createdEpisode.id],
          [openedPlaylist.id],
          false,
        ).then();
      } else {
        episode['id'] = `temp-${generateUUID()}`;
      }
      dispatch(AC_SetVideoTimeZoomAction(0));
      let element;
      let existingEpisodes;
      let video;
      let newOrderedEpisodes;
      if (playerMode === MODES.playlist_view && openedPlaylist) {
        const filtered = filterAndIndex(
          openedPlaylist.playlist_events,
          playedVideoSet?.id,
          'id',
        );
        video = filtered.element;
        existingEpisodes = filtered.element.episodes;
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const filtered = filterAndIndex(
          filteredVideos,
          playedVideoSet?.id,
          'id',
        );
        element = filtered.element;
        if (!element) {
          video = filteredVideos.filter(
            (el: any) => el.activity_videos[0].id === activeVideo?.videoId,
          )[0];
          existingEpisodes = video.user_episodes || [];
        } else {
          video = playedVideoSet;
          existingEpisodes = element?.user_episodes || [];
        }
        newOrderedEpisodes = [...existingEpisodes, episode].sort(
          (a, b) => a.user_timer.started_at - b.user_timer.started_at,
        );
      }
      if (playerMode === MODES.playlist_view && openedPlaylist) {
        if (video) {
          dispatch(
            addUserEpisodeToOpenedPlaylist({
              eventId: video.id,
              episode: episode,
            }),
          );
        }
      } else {
        video &&
          dispatch(
            AC_ReplaceUserEventsToExpandedVideo(newOrderedEpisodes, video.id),
          );
      }
      setEpisodeName(t('New episode'));
      dispatch(AC_SetAllowPlayFirstAction(false));
      if (playerMode === MODES.playlist_view) {
        dispatch(
          showNotification({
            notificationParameters: [
              {
                id: generateUUID(),
                text: `${t('Episode was added to the playlist')} "${openedPlaylist?.name}"`,
                callbackName: 'addPlaylistEpisodeToAnotherPlaylist',
                userEpsiodeId: episode.id,
              },
            ],
          }),
        );
      } else {
        dispatch(
          showNotification({
            notificationParameters: [
              {
                id: generateUUID(),
                text: t(
                  'Episode was created, but will be removed after closing or updating episodes list. To keep it add the epside to a playlist',
                ),
                callbackName: 'addToPlaylist',
                userEpsiodeId: episode.id,
              },
            ],
          }),
        );
      }
      closeCallback();
    }
  };

  const [fromInputStatus, setFromInputStatus] = useState<InputStatus>();
  const [toInputStatus, setToInputStatus] = useState<InputStatus>();
  const saveButtonRef = useRef(null);
  return (
    <div className="new-event-controller-wrapper">
      <div style={{ width: 71 }}>
        <ConfigProvider
          theme={{
            token: {
              colorTextPlaceholder: 'var(--colorTextPlaceholder)',
              fontFamily: 'SFProTextRegular',
              fontSize: 14,
              colorBorder: 'var(--colorBorder)',
              colorText: 'white',
            },
            components: {
              Input: {
                colorBgContainer: 'var(--colorBgContainer)',
              },
            },
          }}
        >
          <Input
            pattern={'^([0-9]{1,3}){1}:([0-9]{2}){1}$'}
            value={inputValueFrom}
            allowClear={false}
            onFocus={() => dispatch(AC_SetPlayerHotkeysAllowedAction(false))}
            onChange={(event) =>
              handleVideoTimeInputChange(event, setInputValueFrom)
            }
            onBlur={(event) => {
              if (event.relatedTarget === saveButtonRef?.current) {
                return;
              }
              const isValid = handleBlur(event.target.value, setFrom, 'from');
              if (isValid) {
                setFromInputStatus('');
              } else {
                setFromInputStatus('error');
              }
              // formatTimeMillisecondsFrom(event.target.value)
            }}
            status={fromInputStatus}
            placeholder=""
            style={{ width: '71px' }}
          />
        </ConfigProvider>
      </div>

      <div style={{ width: 71 }}>
        <ConfigProvider
          theme={{
            token: {
              colorTextPlaceholder: 'var(--colorTextPlaceholder)',
              fontFamily: 'SFProTextRegular',
              fontSize: 14,
              colorBorder: 'var(--colorBorder)',
              colorText: 'white',
            },
            components: {
              Input: {
                paddingInline: 12,
                colorBgContainer: 'var(--colorBgContainer)',
              },
            },
          }}
        >
          <Input
            pattern={'^([0-9]{1,3}){1}:([0-9]{2}){1}$'}
            value={inputValueTo}
            allowClear={false}
            onFocus={() => dispatch(AC_SetPlayerHotkeysAllowedAction(false))}
            onChange={(event) =>
              handleVideoTimeInputChange(event, setInputValueTo)
            }
            onBlur={(event) => {
              if (event.relatedTarget === saveButtonRef?.current) {
                return;
              }
              const isValid = handleBlur(event.target.value, setTo, 'to');
              if (isValid) {
                setToInputStatus('');
              } else {
                setToInputStatus('error');
              }
            }}
            status={toInputStatus}
            placeholder=""
            style={{ width: '71px' }}
          />
        </ConfigProvider>
      </div>

      <div style={{ flex: 1 }}>
        <ConfigProvider
          theme={{
            token: {
              colorTextPlaceholder: 'var(--colorTextPlaceholder)',
              fontFamily: 'SFProTextRegular',
              fontSize: 14,
              colorBorder: 'var(--colorBorder)',
              colorText: 'white',
            },
            components: {
              Input: {
                paddingInline: 12,
                colorBgContainer: 'var(--colorBgContainer)',
                colorTextPlaceholder: 'var(--colorTextPlaceholder)',
                fontSize: 14,
                fontFamily: 'SFProTextRegular',
              },
            },
          }}
        >
          <Input
            type="text"
            // style={{width: "96%"}}
            value={episodeName}
            allowClear={false}
            onBlur={() => dispatch(AC_SetPlayerHotkeysAllowedAction(true))}
            onFocus={() => dispatch(AC_SetPlayerHotkeysAllowedAction(false))}
            onChange={(e) => setEpisodeName(e.target.value)}
            placeholder={t('New episode')}
          />
        </ConfigProvider>
      </div>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              defaultBg: 'var(--colorBgContainer)',
              colorText: 'var(--white)',
              colorBorder: 'var(--colorBorder)',
              defaultHoverBg: 'var(--colorBgContainer)',
              defaultActiveBg: 'var(--colorBgContainer)',
              lineHeight: 22,
            },
          },
        }}
      >
        <Button
          style={{ padding: '0px 16px 0px 16px' }}
          type="text"
          onClick={() => {
            closeCallback();
          }}
        >
          {t('Cancel ss')}
        </Button>
      </ConfigProvider>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              defaultBg: 'var(--colorPrimaryBase)',
              colorText: 'var(--white)',
              colorBorder: 'var(--colorBorder)',
              defaultHoverBg: 'var(--colorBgContainer)',
              defaultActiveBg: 'var(--colorBgContainer)',
              paddingBlockLG: 16,
            },
          },
        }}
      >
        <Button
          ref={saveButtonRef}
          onClick={() => {
            addUserEpisode();
          }}
          style={{ padding: '0px 16px 0px 16px' }}
          type="primary"
        >
          {t('Create')}
        </Button>
      </ConfigProvider>
    </div>
  );
};
export default CreateEpisodePlayerController;
