import React from 'react';

import { Flex, Skeleton } from 'antd';

const TopBarDetailsSkeleton = () => {
  return (
    <Flex gap={12}>
      <Skeleton.Button
        active
        style={{
          width: 56,
          height: 12,
          background:
            'linear-gradient(90deg, rgba(255, 255, 255, 0.1) 25%, var(--ant-skeleton-gradient-to-color) 37%, rgba(255, 255, 255, 0.1) 63%)',
          backgroundSize: '400% 100%',
        }}
      ></Skeleton.Button>
      <Skeleton.Button
        active
        style={{
          width: 72,
          height: 12,
          background:
            'linear-gradient(90deg, rgba(255, 255, 255, 0.1) 25%, var(--ant-skeleton-gradient-to-color) 37%, rgba(255, 255, 255, 0.1) 63%)',
          backgroundSize: '400% 100%',
        }}
      ></Skeleton.Button>
      <Skeleton.Button
        active
        style={{
          width: 48,
          height: 12,
          background:
            'linear-gradient(90deg, rgba(255, 255, 255, 0.1) 25%, var(--ant-skeleton-gradient-to-color) 37%, rgba(255, 255, 255, 0.1) 63%)',
          backgroundSize: '400% 100%',
        }}
      ></Skeleton.Button>
    </Flex>
  );
};
export default TopBarDetailsSkeleton;
